export default {
  targetCenter: {
    target_center: 'Target Center',
    task_rules: 'Task Rules',
    my_functions: 'My Functions',
    post_video_num: 'Post {num} / day',
    get_income: 'Get income',
    more_benefits: 'Get more benefits',
    pro_target: 'Pro Target',
    pro_help: 'Conditions for Pro: 20 posts have been posted, and the content has been reviewed and passed by more than 80%',
    post_videos: 'Post {num} videos',
    pro_pass: 'Pass Rate ≥80%',
    pro_pass_label: '(Pass Rate = Online / Upload)',
    pro_label3: 'You can still apply for Pro {num} times.',
    pro_label4: 'You have reached the apply limit.',
    pro_label5: "Our review team believe that a significant portion of your channel does not meet our policies and guidelines.You'll want to make some changes to your channel before you apply again.",
    pro_label6: 'There is an illegal operation on your account, and the withdraw function cannot be obtained.',
    pro_label7: 'You need to upload more high-quality content in order to meet the requirements for pro',
    pro_label8: 'Your application for Pro has been submitted, and the<br>information will be reviewed within <span>1-3 working days.</span>',
    original_label1: 'Original Target',
    original_label2: 'Original application requirements: complete the Pro task',
    original_label3: 'You can still apply for Original {num} times.',
    original_label4: 'Complete the Pro task',
    apply: 'Apply',
    under_process: 'Under Process',
    i_know: 'I know'
  }
}
