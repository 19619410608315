import Main from '@/components/layout/Main.vue';

const Post = () => import('@/views/post/');
const OgcPost = () => import('@/views/postOgc/');
const OgcWorldPost = () => import('@/views/postOgcWorld/');
const UgcPost = () => import('@/views/postUgc/');
const MiniUgcPost = () => import('@/views/postMiniUgc/');
const PostWorld = () => import('@/views/postWorld/');
const Content = () => import('@/views/content/index');
const ContentDetail = () => import('@/views/content/detail');
// const OgcContent = () => import('@/views/contentOgc/index');
// const OgcContentDetail = () => import('@/views/contentOgc/detail');
const UgcContent = () => import('@/views/contentUgc/index');
const UgcContentDetail = () => import('@/views/contentUgc/detail');
const MiniUgcContent = () => import('@/views/contentMiniUgc/index');
const MiniUgcContentDetail = () => import('@/views/contentMiniUgc/detail');
// const WorldContent = () => import('@/views/contentWorld/index');
// const WorldContentDetail = () => import('@/views/contentWorld/detail');
// const Analytics = () => import('@/views/analytics/index');
const Settings = () => import('@/views/settings/index');
// const VideoSync = () => import('@/views/videoSync');
const Task = () => import('@/views/task');
const Message = () => import('@/views/message');
// const Comments = () => import('@/views/comments');
// const Academy = () => import('@/views/academy');
// const Followers = () => import('@/views/followers');
const Activity = () => import('@/views/activity');
const Medal = () => import('@/views/medal');

export default {
  path: '/',
  component: Main,
  children: [
    // pgc上传
    {
      path: '/post',
      component: Post,
      name: 'post'
    },
    {
      path: '/post/video/edit',
      component: Post,
      name: 'videoEdit'
    },
    // ogc上传
    {
      path: '/ogc/post',
      component: OgcPost,
      name: 'ogcPost'
    },
    {
      path: '/ogc/post/video/edit',
      component: OgcPost,
      name: 'ogcVideoEdit'
    },
    // ogc世界账号特殊上传
    {
      path: '/ogc_world/post',
      component: OgcWorldPost,
      name: 'ogcPostWorld'
    },
    {
      path: '/ogc_wprld/post/video/edit',
      component: OgcWorldPost,
      name: 'ogcVideoEditWorld'
    },
    // ugc上传
    {
      path: '/ugc/post',
      component: UgcPost,
      name: 'ugcPost'
    },
    {
      path: '/ugc/post/video/edit',
      component: UgcPost,
      name: 'ugcVideoEdit'
    },
    // mini ugc上传
    {
      path: '/miniugc/post',
      component: MiniUgcPost,
      name: 'miniUgcPost'
    },
    {
      path: '/miniugc/post/video/edit',
      component: MiniUgcPost,
      name: 'miniUgcVideoEdit'
    },
    // world上传
    {
      path: '/world/post',
      component: PostWorld,
      name: 'worldPost'
    },
    {
      path: '/world/post/video/edit',
      component: PostWorld,
      name: 'worldVideoEdit'
    },
    // pgc内容列表
    {
      path: '/content',
      component: Content,
      name: 'content'
    },
    {
      path: '/content/detail',
      component: ContentDetail,
      name: 'contentDetail'
    },
    // ogc内容列表
    {
      path: '/ogc/content',
      component: Content,
      name: 'ogcContent'
    },
    {
      path: '/ogc/content/detail',
      component: ContentDetail,
      name: 'ogcContentDetail'
    },
    // ugc内容列表
    {
      path: '/ugc/content',
      component: UgcContent,
      name: 'ugcContent'
    },
    {
      path: '/ugc/content/detail',
      component: UgcContentDetail,
      name: 'ugcContentDetail'
    },
    // mini ugc内容列表
    {
      path: '/miniugc/content',
      component: MiniUgcContent,
      name: 'miniUgcContent'
    },
    {
      path: '/miniugc/content/detail',
      component: MiniUgcContentDetail,
      name: 'miniUgcContentDetail'
    },
    // world内容列表
    {
      path: '/world/content',
      component: Content,
      name: 'worldContent'
    },
    {
      path: '/world/content/detail',
      component: ContentDetail,
      name: 'worldContentDetail'
    },
    // {
    //   path: '/analytics',
    //   component: Analytics,
    //   name: 'analytics'
    // },
    {
      path: '/settings',
      component: Settings,
      name: 'settings'
    },
    {
      path: '/task',
      component: Task,
      name: 'task'
    },
    // {
    //   path: '/sync',
    //   component: VideoSync,
    //   name: 'sync'
    // },
    {
      path: '/message',
      component: Message,
      name: 'message'
    },
    // {
    //   path: '/comments',
    //   component: Comments,
    //   name: 'comments'
    // },
    // {
    //   path: '/academy',
    //   component: Academy,
    //   name: 'academy'
    // },
    // {
    //   path: '/followers',
    //   component: Followers,
    //   name: 'followers'
    // },
    {
      path: '/activity',
      component: Activity,
      name: 'activity'
    },
    {
      path: '/medal',
      component: Medal,
      name: 'medal'
    }
  ]
}
