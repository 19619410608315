export default {
  settings: {
    // Personal information
    personal_info: 'Personal information',
    id: 'ID',
    user_name: 'User Name',
    icon: 'Icon',
    nick_name: 'Nickname',
    introduction: 'Introduction',
    country: 'Country',
    language: 'Language',
    field: 'Field',
    // Contact information
    contact_info: 'Contact information',
    email: 'Email',
    email_verification: 'Email verification',
    verification_code: 'Verification code',
    get_verification_code: 'Get a verification code',
    password_tip: 'If you want to log in to WeMedia at this email address, please set your password',
    phone: 'Phone',
    phone_number: 'Phone number',
    phone_verification: 'Phone verification',
    // Bound App
    bound_app: 'Bound App',
    bind: 'Bind',
    // Add a credit or debit card
    card_title: 'Add a credit or debit card',
    bank_name: 'Bank Name',
    bank_country: 'Bank Country',
    bank_no: 'Bank Account No.',
    account_name: 'Account Name',
    country_region: 'Country/region',
    province: 'State/Province',
    city: 'City',
    address: 'Address',
    address_placeholder: 'eg: **Street, House number, sub-district, Districts, City districts, Province, Postal code',
    // Certification information
    certification_info: 'Certification information',
    operation_tips: 'Operation Tips',
    youtube_url_title: 'YouTube source url',
    youtube_certificate_title: 'Upload YouTube Certificate',
    facebook_url_title: 'Facebook source url',
    facebook_certificate_title: 'Upload Facebook Certificate',
    tiktok_url_title: 'Tiktok source url',
    tiktok_certificate_title: 'Upload Tiktok Certificate',
    snackvideo_url_title: 'Snackvideo source url',
    snackvideo_certificate_title: 'Upload Snackvideo Certificate',
    under_process: 'Under Process',
    connect: 'Connect',
    details: 'Details',
    deny_reasons: 'Deny reasons'

  }
}
