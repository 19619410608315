export default {
  withdraw: {
    withdraw: 'Tarik Uang',
    available_balance: 'Saldo Tersedia',
    total_balance: 'Saldo Total',
    withdraw_record: 'Catatan Pendapatan',
    withdraw_amount: 'Jumlah Pendapatan(USD)',
    tax: 'Tax(USD)',
    payment_amount: 'Jumlah Pendapatan(USD/After-tax)',
    payment_status: 'Status Pembayaran',
    status_list: [{
      label: 'Sedang dalam proses',
      value: 1
    }, {
      label: 'Pembayaran sukses',
      value: 2
    }, {
      label: 'Pembayaran gagal',
      value: 3
    }],
    withdraw_label1: '(Saldo sebesar ${num} telah dibekukan'
  }
}
