import axios from '@/utils/request';

/**
 * @name 获取引导列表
 */
export function getGuideList() {
  return axios.post('/shareit/pgc/guidance/list');
}

/**
 * @name 上报引导
 */
export function saveGuide(params) {
  return axios.post('/shareit/pgc/guidance/confirm', { ...params });
}
