<template>
  <div class="st-footer">
    <div class="st-copy">
      <p class="st-copyInfo">Copyright © 2019 SHAREit</p>
    </div>
    <!-- <div class="st-wrap">
      <div class="st-nav">
        <ol class="st-navContent">
          <li class="st-navInfo">
            <router-link to="/about" target="_blank">{{ $t('common.about') }}</router-link>
          </li>
          <el-divider direction="vertical"></el-divider>
          <li class="st-navInfo">
            <a
              href="http://cdn.ushareit.com/shareit/w/pgc/tos.html"
              target="_blank"
            >{{ $t('common.terms') }}</a>
          </li>
          <el-divider direction="vertical"></el-divider>
          <li class="st-navInfo">
            <a
              href="http://cdn.ushareit.com/shareit/w/pgc/privacy.html"
              target="_blank"
            >{{ $t('common.privacy') }}</a>
          </li>
          <el-divider direction="vertical"></el-divider>
          <li class="st-navInfo">
            <a
              href="https://mp-doc.wshareit.com/#/1?page_id=4&si=1&lang=en"
              target="_blank"
            >{{ $t('common.creator_guide') }}</a>
          </li>
        </ol>
      </div>
      
    </div> -->
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import { mapGetters } from "vuex";
export default {
  name: "StFooter",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["clientHeight", "clientWidth"]),
    styles() {
      if (this.clientWidth > 1200) {
        return { width: this.clientWidth + "px" };
      } else {
        return { width: "1200px" };
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.st-footer {
  position: relative;
  height: 50px;
  background: #ffffff;
  box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, 0.04);
}
.st-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 1200px;
}
.st-nav {
  position: relative;
  height: 42px;
  padding: 26px 0 0 0;
  text-align: center;
  border-bottom: 0.5px solid #e6e6e6;
}
.st-navContent {
  display: inline-block;
  margin: 0 auto;
  height: 20px;
  color: #606060;
  overflow: hidden;
  .st-navInfo {
    display: inline-block;
    padding: 0 10px;
    line-height: 20px;
    font-size: 12px;
    font-family: Roboto-Regular;
    cursor: pointer;
  }
}
.st-copy {
  position: absolute;
  width: 100%;
  height: 50px;
  .st-copyInfo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 200px;
    height: 20px;
    font-family: PingFangSC-Regular, sans-serif;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
    text-align: center;
  }
}
</style>
