import router from './router';
import store from './store';
import report from '@/utils/report';
// eslint-disable-next-line no-undef
const MessageBox = ELEMENT.MessageBox;
const whiteList = ['/login', '/inviteRegister', '/about', '/forgetPwd', '/banned', '/agreement'];
const whiteListName = ['resetPwd'];
const ogcPath = ['post', 'content', 'videoEdit'];
router.beforeResolve((to, from, next) => {
  // 缓存活动referrer
  if (to.query.referrer) {
    sessionStorage.setItem('WeMedia_REFERRER', to.query.referrer);
  }
  if (whiteList.indexOf(to.path) > -1 || whiteListName.indexOf(to.name) > -1 || to.path.indexOf('/register') > -1) {
    next();
  } else {
    const token = localStorage.getItem('LOGIN_TOKEN');
    if (token) {
      // 刷新页面初始化用户信息
      if (!store.getters.userInfo) {
        store.dispatch('getUserInfo').then(res => {
          next(to.fullPath);
        }).catch((error) => {
          next();
          console.log(error);
        });
      } else {
        // 每次路由进行用户状态校验，防止注册阶段手动修改path进入系统
        const status = store.getters.userInfo.status;
        if (status === 7) {
          next('/banned');
        } else {
          if (store.getters.menuList.length === 0) {
            store.dispatch('InitRouters');
            next(to.fullPath);
          }
          const level = store.getters.userInfo.level;
          const agreeTermsTime = store.getters.userInfo.agreeTermsTime;
          const agreePrivacyTime = store.getters.userInfo.agreePrivacyTime;
          // 0-9用户授权协议同意校验
          if (level >= 0 && level <= 9 && (!agreeTermsTime || !agreePrivacyTime)) {
            next('/agreement');
            return;
          }
          if (to.path.indexOf('/post') > -1) {
            if (status === 2) {
              MessageBox.confirm('The current information is incomplete, post functions will be available when you complete it.', '', {
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Complete your information',
                center: true
              }).then(() => {
                if (from.name !== 'settings') {
                  next('/settings');
                } else {
                  window.location.reload();
                }
              }).catch(() => {
                if (from.name === 'settings') {
                  window.location.reload();
                }
              });
              return;
            } else if (status === 8) {
              MessageBox.alert('Your account is waiting for an information audit, please upload video after been proved.', '', {
                confirmButtonText: 'OK',
                center: true,
                callback: () => {
                  if (from.path === '/') {
                    next('/content');
                  }
                }
              });
              return;
            } else if (status === 9) {
              MessageBox.alert('Your registration information has not been passed, please edit your information and apply for audit again. Functions will be accessible after being proved.', '', {
                confirmButtonText: 'To modify',
                center: true,
                callback: () => {
                  if (from.name !== 'settings') {
                    next('/settings');
                  } else {
                    window.location.reload();
                  }
                }
              });
              return;
            }
            // 用户信息完善
            if (status === 3 && (!store.getters.userInfo.userName || !store.getters.userInfo.nickName || !store.getters.userInfo.avatar || !store.getters.userInfo.country || !store.getters.userInfo.lang || !store.getters.userInfo.labels)) {
              MessageBox.alert('Your account is under audit process, all functions will be available when you pass the audit.', '', {
                confirmButtonText: 'OK',
                center: true,
                callback: action => {
                  if (from.name !== 'settings') {
                    next('/settings');
                  } else {
                    window.location.reload();
                  }
                }
              });
              return;
            } else if (!store.getters.userInfo.userName || !store.getters.userInfo.nickName || !store.getters.userInfo.avatar || !store.getters.userInfo.country || !store.getters.userInfo.lang || !store.getters.userInfo.labels) {
              MessageBox.confirm('The current information is incomplete, post functions will be available when you complete it.', '', {
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Complete your information',
                center: true
              }).then(() => {
                if (from.name !== 'settings') {
                  next('/settings');
                } else {
                  window.location.reload();
                }
              }).catch(() => {
                if (from.name === 'settings') {
                  window.location.reload();
                }
              });
              return;
            }
            // 账号打通限制需求  https://pms.ushareit.me/index.php?m=story&f=view&storyID=7017
            const cendBounedFlag = store.getters.userInfo.cendBounedFlag;
            if (!cendBounedFlag) {
              MessageBox.alert('You cannot upload video as a matching account is not found on the App side.', '', {
                confirmButtonText: 'OK',
                center: true,
                callback: () => {
                  if (from.path === '/') {
                    next('/content');
                  }
                }
              });
              return;
            }
          }

          const userid = store.getters.userInfo.userid;
          if (ogcPath.indexOf(to.name) > -1) {
            // pgc ogc用户跳转判断
            if (level >= 0 && level <= 9) {
              // pgc世界账号
              const worldUserIds = ['amdR', 'amer', 'amcr', 'g8go', 'a5dc', 'herm'];
              if (worldUserIds.indexOf(userid) > -1) {
                next({
                  path: '/world' + to.path,
                  query: to.query
                });
              } else {
                next();
              }
            } else if (level >= 70 && level <= 79) {
              next({
                path: '/ugc' + to.path,
                query: to.query
              });
            } else if (level >= 80 && level <= 89) {
              next({
                path: '/miniugc' + to.path,
                query: to.query
              });
            } else if (level >= 20) {
              next({
                path: '/ogc' + to.path,
                query: to.query
              });
            }
          } else if (['ogcPost', 'ogcVideoEdit'].indexOf(to.name) > -1 && ['2nMM', '2nNO'].indexOf(userid) > -1) {
            next({
              name: to.name + 'World',
              query: to.query
            });
          } else {
            next();
          }
        }
      }
    } else {
      if (to.name && to.name.toLowerCase().match('post') || to.name === 'activityApply') {
        sessionStorage.setItem('LOGIN_FROM', to.fullPath);
      }
      const _q = {};
      if (to.query.aid) {
        _q.aid = to.query.aid;
      }
      next({
        path: '/login',
        query: _q
      });
    }
  }
});
router.afterEach((to, from) => {
  report(`/wm_${to.name}/x/x`, {
    ...to.query,
    ...to.params
  })
});
