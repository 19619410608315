export default {
  common: {
    about: 'Tentang',
    terms: 'Syarat & Ketentuan',
    privacy: 'Kebijakan Privasi',
    notice: 'Pemberitahuan',
    comment: 'Komentar',
    followers: 'Pengikut',
    poked: 'Colek',
    settings: 'Pengaturan',
    password: 'Password',
    sign_out: 'Sign out',
    start_date: 'Tanggal Permulaan',
    end_date: 'Tanggal Akhir',
    range_separator: 'To',
    edit: 'Ubah',
    delete: 'Hapus',
    appeal: 'Gunakan',
    submit: 'Kirim',
    cancel: 'Batalkan',
    month: 'Bulan',
    week: 'Minggu',
    yesterday: 'Kemarin',
    day: 'Hari',
    back: 'Kembali',
    save: 'Simpan',
    select_one: 'Pilih Satu',
    example: 'Contoh',
    title: 'Judul',
    time: 'Waktu',
    data_trend_table: 'Tabel Trend Data',
    province: 'Provinsi',
    city: 'Kota',
    no_data: 'Tidak ada data',
    creator_guide: 'Petunjuk Kreator',
    feedback: 'Umpan Balik'
  }
}
