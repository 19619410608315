// 将日期时间格式，转换成时间戳(10位 秒)

export function dateTotime(date) {
  return (Date.parse(new Date(date).toString())) / 1000;
}

// 将时间戳转换成日期时间格式
export function timeFormat(time, fmt) {
  if (!time) return;
  fmt = fmt || 'yyyy-MM-dd hh:mm';
  time = time.toString();
  if (time.length === 10) {
    time = time * 1000;
  } else {
    time = time * 1;
  }

  const date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (const k in o) {
    const str = o[k] + '';
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

/**
 * 通过val匹配与arr中与valueKey的值相同的数据，并返回labelKey对应的值
 * @param {*} arr 数据列表
 * @param {*} val 值
 * @param labelKey 需要返回的数据key 默认为 label
 * @param valueKey  val对应的数据key 默认为 value
 * @param defaultVal  匹配不到label是否默认返回val
 */
export function valueToLabel(val, arr, labelKey, valueKey, defaultVal) {
  let label = '';
  if (val === '' || val === null || !(arr instanceof Array) || arr.length === 0) {
    return '';
  }
  labelKey = labelKey || 'label';
  valueKey = valueKey || 'value';
  const item = arr.find(obj => obj[valueKey] === val);
  if (item && item[labelKey] !== undefined) {
    label = item[labelKey];
  } else if (defaultVal) {
    label = val;
  }
  return label;
}

/**
 * @name 时间戳转字符串
 * @param {*} time
 * @param {*} fmt
 */
export function timeToStr(time, fmt) {
  if (!time) return;
  time = time.toString();
  if (time.length === 10) {
    time = time * 1000;
  } else {
    time = time * 1;
  }
  const nowTime = new Date().getTime();
  const diff = nowTime - time;
  if (diff <= 60 * 1000) {
    return parseInt(diff / 1000) + 's ago';
  } else if (diff <= 60 * 60 * 1000) {
    return parseInt(diff / 60 / 1000) + 'm ago';
  } else if (diff <= 24 * 60 * 60 * 1000) {
    return parseInt(diff / 60 / 60 / 1000) + 'h ago';
  } else if (diff <= 7 * 24 * 60 * 60 * 1000) {
    return parseInt(diff / 24 / 60 / 60 / 1000) + 'd ago';
  } else if (nowTime - time < 8 * 24 * 60 * 60 * 1000) {
    return '1w ago';
  } else if (new Date().getFullYear() === new Date(time).getFullYear()) {
    return timeFormat(time, 'MM-dd');
  } else {
    return timeFormat(time, 'yyyy-MM-dd');
  }
}
/**
 * @name 数字转K
 * @param {*} time
 * @param {*} fmt
 */
export function numberToK(val) {
  val = Number(val) || 0;
  if (val < 1000) {
    return val;
  }
  val = Math.ceil(val / 100) / 10;
  return val + 'K'
}

/**
 * 毫秒转换为00：00：00
 * @param {*} s
 */
export function msToTime(s) {
  var t;
  if (s > -1) {
    s = Math.floor(s / 1000);
    var hour = Math.floor(s / 3600);
    var min = Math.floor(s / 60) % 60;
    var sec = s % 60;
    if (hour < 10) {
      t = '0' + hour + ':';
    } else {
      t = hour + ':';
    }

    if (min < 10) { t += '0'; }
    t += min + ':';
    if (sec < 10) { t += '0'; }
    t += sec;
  }
  return t;
}

/**
 * 秒转换为00：00
 * @param {*} s
 */
export function secToTime(s) {
  var t;
  if (s > -1) {
    s = Math.floor(s);
    var min = Math.floor(s / 60);
    var sec = s % 60;

    if (min < 10) {
      t = '0' + min + ':';
    } else {
      t = min + ':';
    }
    if (sec < 10) { t += '0'; }
    t += sec;
  } else {
    t = '--:--';
  }
  return t;
}

