import { getGuideList, saveGuide } from '@/api/guide'
// state
const state = {
  guideModal: false,
  guideList: []
};

// getters

const getters = {
  guideModal: (state) => state.guideModal,
  guideList: (state) => state.guideList
};

// mutations

const mutations = {
  SET_GUIDE_MODAL(state, val) {
    state.guideModal = val;
  },
  SET_GUIDE_LIST(state, val) {
    state.guideList = val;
  }
};

// actions

const actions = {
  // 切换引导蒙层
  setGuideModal({ commit, state }, val) {
    commit('SET_GUIDE_MODAL', val);
  },
  // 通过当前引导
  saveCurrentGuide({ commit, state }, val) {
    saveGuide({ name: val });
    const _guideList = [...state.guideList];
    if (_guideList.length > 0) {
      _guideList.splice(0, 1);
      commit('SET_GUIDE_LIST', _guideList);
    }
  },
  // 前端跳过当前引导
  skipCurrentGuide({ commit, state }) {
    const _guideList = [...state.guideList];
    if (_guideList.length > 0) {
      _guideList.splice(0, 1);
      commit('SET_GUIDE_LIST', _guideList);
    }
  },
  // 初始化待引导数据
  initGuide({ commit }) {
    // getGuideList().then(res => {
    //   res = res.data;
    //   if (res.code === 0) {
    //     if (res.data && res.data.length > 0) {
    //       commit('SET_GUIDE_LIST', res.data.map(item => item.name));
    //     }
    //   }
    // })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
