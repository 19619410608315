export default {
  post: {
    video: 'Video',
    file_upload: 'Click upload or drag the file into this area',
    reupload: 'Reupload',
    upload_rule: '<span>Size: &lt; 10GB</span><span>Resolution: &gt; 240P</span><span>Aspect ratio: ≥1</span><span>10 ≤ time ≤1h</span>',
    post_rule_title1: 'Recommended format',
    post_rule_label1: 'Mp4 is recommended',
    post_rule_title2: 'Recommended definition',
    post_rule_title3: 'Recommended size',
    post_rule_label3: 'Less than 2G, within 10 minutes',
    title: 'Title',
    title_placeholder: 'Your video needs a title and it is limited to 120 characters.',
    description: 'Description',
    description_placeholder: 'Tell viewers about your video.',
    language: 'Language',
    label: 'Label',
    tag: 'Tag',
    horizontal_cover: 'Horizontal Cover',
    original_video: 'Original Video',
    original: 'Original',
    non_original: 'Non-Original',
    schedule: 'Schedule',
    schedule_tip: 'Select a date to make your video public',
    publish: 'Publish',
    save_draft: 'Save as draft',
    change: 'Change',
    cover_tip: 'Notice: Large and clear cover photos will be helpful to represent the content.',
    required: 'Required',
    save_success: 'Saved successfully!',
    join_event: 'Join the event',
    end_in: 'End in',
    day: 'days',
    reward: 'Reward',
    joined: 'joined',
    activity_label1: 'Video need to be {min} ~ {max} seconds for this event',
    activity_label2: 'Please choose label {label} for this event',
    details: 'Details'
  }
}
