export default {
  functions: {
    functions: 'Functions',
    // pro
    pro_label1: 'The channel will be upgraded to Pro when you online 20 videos and reaches 80% online rate.',
    pro_label2: 'The operating ability of your channel will be valued with content contribution and online rate. You can apply for certain functions if you meet the requirements.',
    pro_label3: 'Fresher & Pro',
    pro_label4: 'All creators can apply for Pro when you online 20 videos and reach 80% online rate. All creators who applied for Pro will be reviewed by our team to check if the content quality meets the requirements of Pro. The Withdraw function will be accessible if you pass the audit for Pro.',
    pro_label5: 'You can still apply for Pro {num} times.',
    pro_label6: 'You have reached the apply limit.',
    pro_label7: "Our review team believe that a significant portion of your channel does not meet our policies and guidelines.You'll want to make some changes to your channel before you apply again.",
    pro_label8: 'There is an illegal operation on your account, and the withdraw function cannot be obtained.',
    pro_label9: 'Your application for Pro has been submitted, and the<br>information will be reviewed within <span>1-3 working days.</span>',
    apply: 'Apply',
    under_process: 'Under Process',
    i_know: 'I know',
    // Rights
    rights: 'Rights',
    rights_label1: 'You can upload up to {num} videos per day.',
    rights_label2: 'You can add following guide to the online videos.',
    // Earning
    earning: 'Earning',
    earning_label1: 'Your video generates traffic after being watched,thereby gaining revenue.',
    // original
    original_label1: 'You can apply for the Original to obtain more recommendation and high revenue.',
    original_label2: 'Your application for Original has been submitted, and the<br>information will be reviewed within <span>1-3 working days.</span>',
    original_label3: 'Apply Tips',
    original_label4: 'Congratulations, you have become a Pro creator now and open access to revenue. <span class="mark">Want to get higher revenue?</span>',
    original_label5: '<p class="p">Please offer your account on other platform as certification which will:</p>1. Helps with passing the Original function audit<br>2. Help us to offer you more suitable service and recommend',
    original_label6: 'I have read and agreed with the <a href="/original/about" target="_blank">《Original Declaration》</a>.',
    original_label7: 'Please read the 《Original Declaration》.',
    // agency bind
    agency_bind: 'Agency Bind',
    agency_label1: 'Bind your account to your agency and enable it to check your data.',
    agency_label2: 'Agency',
    agency_label3: 'Invitation time',
    agency_label4: 'Join time',
    agency_label5: 'I have read and agreed with the <a href="/agency/about" target="_blank" @click="checkDisable = false">Agency Bind Policy</a>.',
    agency_label6: 'Agree',
    agency_label7: 'Refuse',
    agency_label8: 'Bind',
    agency_label9: 'Info',
    agency_label10: 'Signing Tips'
  }
}
