export default {
  sync: {
    video_sync: 'Sinkronisasi Video',
    sync_label1: 'Sinkronisasi video untuk akun Anda',
    sync_label2: 'Pesan YouTube',
    sync_label3: 'URL Kanal',
    sync_label4: 'Aktifkan',
    sync_label5: 'Sedang Diaudit',
    sync_label6: 'Nama Panggilan',
    sync_label7: 'Status',
    sync_label8: 'Telah Diaktifkan',
    sync_label9: 'Hapus Tautan',
    sync_label10: 'Arahan',
    sync_label11: '<li>Link dari channel yang ingin Anda sinkronisasikan harus valid dan sesuai dengan yang telah Anda daftarkan untuk akun ini</li><li>Anda harus merupakan produser asli atau pemilik channel. Plagiarisme atau kecurangan tidak akan diperbolejkan. Penggunaan channel dari produser lain akan berakibat penutupan fungsi sinkronisasi</li><li>Iklan atau link yang tidak sesuai dari website atau aplikasi lainnya tidak akan diperbolehkan untuk muncul di video yang akan disinkronisasi.</li><li>SHAREit WeMedia platform akan melakukan pengecekan video yang disinkronisasi ke akun Anda. Proses sinkronisasi akan dihentikan jika ditemukan adanya terlalu banyak iklan dan konten yang tidak sesuai dengan ketentuan platform kami.</li><li>Standar konten yang dapat disinkronisasi dapat dilihat di Syarat dan Ketentuan</li>',
    sync_label12: 'URL sumber konten',
    sync_label13: 'Upload sertifikat',
    sync_label14: 'Screenshot beranda channel Anda.<br>Maksimal upload: 3 gambar',
    sync_label15: 'Contoh',
    sync_label16: 'Saya telah membaca dan menyetujui kebijakan di atas',
    sync_label17: 'Lisensi Persetujuan'

  }
}
