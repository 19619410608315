export default {
  guide: {
    know: 'Saya Mengetahui',
    post: 'Klik di sini untuk mengunggah video',
    analytics: 'Dapat mengenai video Anda dapat dicek di sini',
    functions: 'Buat permohonan dan lewati audit, maka Anda akan dapat menggunakan fungsi Pendapatan',
    academy: 'Cek video tutorial dan perhatikan petunjuk penggunaan platform ini. Kami berharap Anda akan dapat membuat konten yang lebih baik di masa mendatang.',
    followers: 'Anda dapat memeriksa data dan profil pengikut Anda untuk mengerti preferensi audiens.'
  }
}
