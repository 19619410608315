import axios from '@/utils/request';

/**
 * @name 权益信息获取
 */
export function userTypeInfo() {
  return axios.post('/shareit/pgc/user/author/current');
}

/**
 * @name 转正申请
 */
export function stageApply() {
  return axios.get('/shareit/pgc/user/author/stageApply');
}

/**
 * @name 原创权益信息获取
 */
export function originInfo() {
  return axios.get('/shareit/pgc/origin/current');
}

/**
 * @name 原创申请
 */
export function originApply() {
  return axios.get('/shareit/pgc/origin/apply');
}

/**
 * @name 签约受邀信息
 */
export function mcnInviterInfo() {
  return axios.get('/shareit/pgc/agency/get');
}

/**
 * @name 提交授权
 */
export function submitAuth(params) {
  return axios.post('/shareit/pgc/agency/authorized', { ...params });
}

/**
 * @name 转正任务进度
 */
export function targetDetails() {
  return axios.post('/shareit/pgc/task/pro/detail');
}

/**
 * @name 原创申诉提交
 */
export function originAppeal(params) {
  return axios.post('/shareit/pgc/origin/canceled/appeal', { ...params });
}

/**
 * @name 原创申诉状态查询
 * @returns
 */
export function originAppealStatus() {
  return axios.get('/shareit/pgc/origin/canceled/appeal/status')
}
