<template>
  <el-scrollbar>
    <div id="main">
      <div class="header">
        <st-header-no></st-header-no>
      </div>
      <div class="home">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
      <div class="footer">
        <st-footer></st-footer>
      </div>
    </div>
  </el-scrollbar>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import HeaderNo from '@/components/header/noLogin';
import Footer from '@/components/footer/index.vue';

export default {
  name: 'StMain',
  components: {
    'st-header-no': HeaderNo,
    'st-footer': Footer
  },
  data() {
    return {
      lang: 'en'
    };
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
#main {
  background: #f8f8f8;
  width: 100%;
  min-height: 100vh;
  min-width: 1242px;
  display: flex;
  flex-flow: column;
}
.el-scrollbar {
  width: 100vw;
  height: 100vh;
  ::v-deep {
    .el-scrollbar__wrap {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.header {
  height: 80px;
}

.home {
  flex: 1;
  position: relative;
  background: #f8f8f8;
  margin-bottom: -20px;
  margin-top: -20px;
  overflow: hidden;
  .main-warpper {
    padding: 0 20px;
  }
}
.wrapper {
  flex: 1;
  position: relative;
  margin: auto;
  width: 1202px;
  overflow: hidden;
}

.footer {
  height: 120px;
  padding-top: 20px;
}
</style>
