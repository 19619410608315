import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementIdLocale from 'element-ui/lib/locale/lang/id'; // element-ui lang
import { en, enViews, id, idViews } from './languages';

Vue.use(VueI18n);

const messages = {
  en: {
    ...elementEnLocale,
    ...en,
    ...enViews
  },
  id: {
    ...elementIdLocale,
    ...id,
    ...idViews
  }
};

const i18n = new VueI18n({
  locale: Cookies.get('language') || 'en',
  messages // set locale messages
});

// eslint-disable-next-line no-undef
ELEMENT.i18n((key, value) => i18n.t(key, value));
export default i18n;
