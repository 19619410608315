import Vue from 'vue';
import Vuex from 'vuex';

import base from './modules/base';
import user from './modules/user';
import toggleHandler from './modules/toggleHandler';
import guide from './modules/guide';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user,
    base,
    toggleHandler,
    guide
  },
  strict: debug
});
