export default {
  targetCenter: {
    target_center: 'Target Pusat',
    task_rules: 'Aturan Tugas',
    my_functions: 'Fungsi Saya',
    post_video_num: 'Pos {num} kali/ hari',
    get_income: 'Dapat pemasukkan',
    more_benefits: 'Dapatkan lebih banyak keuntungan',
    pro_target: 'Target Pro',
    pro_help: 'Kondisi untuk pro : Pos 20 video dan 80% konten telah berhasil melalui proses review',
    post_videos: 'Postingan {num} video',
    pro_pass: 'Rate Kelulusan lebih dari 80%',
    pro_pass_label: '(Rate Lulus =Online/ Upload)',
    pro_label3: 'Kamu masih bisa mengajukan untuk  menjadi kreator original sebanyak {num} kali.',
    pro_label4: 'Kamu telah mencapai limit pengajuan',
    pro_label5: 'Tim review kami menemukan kanal Anda tidak sesuai dengan pedoman komunitas aplikasi kami. Anda perlu melakukan perubahan sebelum melakukan pengajuan kembali.',
    pro_label6: 'Terdapat hal ilegal di akun Anda dan fitur penarikan dana tidak akan diberikan',
    pro_label7: 'Anda perlu mengunggah lebih banyak konten berkualitas untuk memenuhi syarat menjadi kreator Pro',
    pro_label8: 'Pengajuan Anda untuk menjadi pro telah dikirimkan<br> dan akan direview dalam <span>1-3 hari kerja</span>',
    original_label1: 'Target Original',
    original_label2: 'Persyaratan untuk mengajukan Original : Menyelesaikan Tugas Pro',
    original_label3: 'Kamu masih bisa mengajukan untuk  menjadi kreator original sebanyak {num} kali.',
    original_label4: 'Menyelesaikan tugas Pro',
    apply: 'Ajukan',
    under_process: 'Dalam Proses',
    i_know: 'Saya mengerti'
  }
}
