import axios from '@/utils/feedbackRequest';
const baseURL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API_FEEDBACK : process.env.VUE_APP_API_PATH_FEEDBACK;
/**
 * @name 保存用户反馈信息
 * @param {*} params
 */
export function saveFeedbackInfo(params) {
  return axios.post('/feedback/save/web/back/info', { ...params });
}

/**
 * @name 上传反馈图片
 */
export const uploadFeedbackImg = `${baseURL}/feedback/file/upload`

/**
 * @name 获取反馈分类列表
 */
export function getClassify(params) {
  return axios.get('/feedback/web/user/feedback/classify', {
    params: { ...params }
  });
}
