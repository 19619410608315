export default {
  content: {
    all: 'Semua',
    publish: 'Publikasi',
    draft: 'Draft',
    pending: 'Tertunda',
    deny: 'Tolak',
    offline: 'Offline',
    transcoding: 'Transcoding',
    failure: 'Transcoding Batal',
    repetition: 'VPengulangan Video',
    published: 'Terkirim',
    under_appeal: 'Dalam Permohonan',
    appeal_through: 'Permohonan Diterima',
    appeal_failed: 'Permohonan Gagal',
    original: 'Original',
    recommend: 'Rekomen',
    clicks: 'Klik',
    comments: 'Komentar',
    cover: 'Gambar Cover',
    comment_placeholder: 'Tambahkan komentar ...',
    load_comments: 'Buka lebih banyak komentar',
    no_comments: 'Tidak ada komentar lagi',
    comments_label1: 'Fitur komentar akan tersedia setelah video dirilis resmi.'
  }
}
