<template>
  <div class="notice-wrap">
    <transition name="fade">
      <div v-if="showTop" class="notice-item" @click="$emit('top')">
        <svg-icon icon-class="top"></svg-icon>
      </div>
    </transition>
    <!-- <el-popover
      placement="left-end"
      title=""
      width="170"
      trigger="hover"
      popper-class="feedback-popover"
    >
      <div slot="reference" class="notice-item">
        <svg-icon icon-class="feedback"></svg-icon>
      </div>
      <div class="nav-inner">
        <a class="nav-item" target="_blank" href="https://mp-doc.wshareit.com/#/1?page_id=4&si=1&lang=en" @click="handleCreator">{{ $t('common.creator_guide') }}</a>
        <div class="nav-item" @click="handleFeedback">{{ $t('common.feedback') }}</div>
      </div>
    </el-popover>
    <st-feedback ref="feedback"></st-feedback> -->
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import feedback from "./feedback";

export default {
  name: "Notice",
  components: {
    stFeedback: feedback,
  },
  data() {
    return {
      showTop: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.listernerPageScroll();
    });
  },
  methods: {
    handleFeedback() {
      this.$report(`/wm_feedback/x/x`);
      this.$refs.feedback.show();
    },
    handleCreator() {
      this.$report(`/wm_creatorguide/x/x`);
    },
    listernerPageScroll() {
      const scrollbarDom =
        document.getElementsByClassName("el-scrollbar__wrap");
      scrollbarDom[0].addEventListener("scroll", () => {
        this.showTop = Boolean(scrollbarDom[0].scrollTop > 0);
      });
      if (scrollbarDom[1]) {
        scrollbarDom[1].addEventListener("scroll", () => {
          this.showTop = Boolean(scrollbarDom[1].scrollTop > 0);
        });
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.notice-wrap {
  z-index: 1000;
  position: fixed;
  right: 0;
  bottom: 140px;
  .notice-item {
    width: 58px;
    height: 58px;
    background: #ffffff;
    border-radius: 6px 0px 0px 6px;
    box-shadow: 0 0 4px 0 #dfe4ea;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: #eceef0;
    }
  }
  .svg-icon {
    width: 30px;
    height: 30px;
  }
}
</style>
<style lang="scss" rel="stylesheet/sass">
.feedback-popover {
  padding: 20px 0 !important;
  box-sizing: border-box;
  .nav-inner {
    .nav-item {
      display: block;
      cursor: pointer;
      line-height: 36px;
      padding-left: 30px;
      font-family: Roboto-Regular;
      font-size: 14px;
      color: #000000;
      &:hover {
        background: #f7f8f9;
      }
    }
  }
}
</style>
