/**
 * Created by superman on 18/4/23.
 * http配置
 */

import axios from 'axios';
import router from '@/router';

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API_PATH_FEEDBACK, // url = base url + request url
  withCredentials: false // send cookies when cross-domain requests
  // timeout: 15000 // request timeout
});

// http request 拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('LOGIN_TOKEN') || '';
    config.headers['token'] = token;
    return config;
  },
  err => {
    Promise.reject(err);
  });

// http response 拦截器
service.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 503 || error.response.data.code === 204) {
      router.replace({ path: '/login' });
      return Promise.reject(error);
    } else {
      // router.push({ path: '/error' });
      // return Promise.reject(error);
    }
    return Promise.reject(error);
  });

export default service;
