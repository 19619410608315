export default {
  functions: {
    functions: 'Fungsi',
    // pro
    pro_label1: 'Kanal Anda akan di-upgrade jika 20 video telah diupload dan mencapai 80% online rate',
    pro_label2: 'Kemampuan operasional channel Anda akan dinilai dari kontribusi konten dan online rate. Anda dapat mengajukan fungsi tertentu jika memenuhi kriteria',
    pro_label3: 'Fresh & Pro',
    pro_label4: 'Seluruh kreator dapat mengajukan akunnya menjadi Pro ketika berhasil mempublikasikan 20 video dan mencapai 80% online rate. Seluruh kreator yang mengajukan fungsi Pro akan direview oleh tim kami untuk melihat apakah kontennya sesuai dengan syarat untuk menjadi Pro. Fungsi penarikan akan dapat diakses jika Anda lolos proses audit menjadi Pro.',
    pro_label5: 'Kamu masih bisa mengajukan untuk  menjadi kreator original sebanyak {num} kali.',
    pro_label6: 'Kamu telah mencapai limit pengajuan',
    pro_label7: 'Tim kami menemukan bahwa channel Anda belum memenuhi syarat dan ketentuan di platform kami. Anda perlu melakukan perubahan sebelum melakukan pengajuan ulang.',
    pro_label8: 'Terdapat kegiatan yang ilegal di akun Anda dan fungsi penarikan akan dinonaktifkan',
    pro_label9: 'Pengajuan Anda untuk menjadi pro telah dikirimkan<br> dan akan direview dalam <span>1-3 hari kerja</span>',
    apply: 'Ajukan',
    under_process: 'Dalam Proses',
    i_know: 'Saya mengerti',
    // Rights
    rights: 'Rights',
    rights_label1: 'Anda dapat mengunggah hingga {num} video per hari',
    rights_label2: 'Anda dapat menambahkan petunjuk pengikut untuk video yang sudah online.',
    // Earning
    earning: 'Pendapatan',
    earning_label1: 'Video Anda menghasilkan traffic setelah ditonton dan menghasilkan revenue',
    // original
    original_label1: 'Anda dapat mengajukan untuk menjadi kreator original untuk menghasilkan lebih banyak rekomendasi dan penghasilan',
    original_label2: 'Aplikasi original telah disubmit dan informasi yang Anda<br> berikan akan direview dalam <span>1-3 hari kerja</span>',
    original_label3: 'Tips Pengajuan',
    original_label4: 'Selamat, Anda telah menjadi kreator Pro sekarang dan akses penghasilan telah dibuka sekarang. <span class="mark">Apakah Anda ingin mendapatkan penghasilan yang lebih tinggi?</span>',
    original_label5: '<p class="p">Mohon lampirkan akun Anda di platform lain yang akan memberikan benefit sebagai berikut:</p>1. Memberikan kemungkinan lebih besar bagi Anda untuk melewati fungsi audit untuk jadi original<br>2. Membantu kami untuk menawarkan layanan dan rekomendasi yang sesuai',
    original_label6: 'Saya telah membaca dan menyetujui <a href="/original/about" target="_blank">(Deklarasi Original)</a>.',
    original_label7: 'Mohon baca (Deklarasi Original)',
    // agency bind
    agency_bind: 'Ikatan Agensi',
    agency_label1: 'Tautkan akun Anda dengan agensi Anda agar data Anda dapat diakses',
    agency_label2: 'Agensi',
    agency_label3: 'Waktu Undangan',
    agency_label4: 'Waktu Bergabung',
    agency_label5: 'Saya telah membaca dan menyetujui <a href="/agency/about" target="_blank" @click="checkDisable = false">syarat dan kebijakan pengikatan agensi</a>.',
    agency_label6: 'Setuju',
    agency_label7: 'Tolak',
    agency_label8: 'Tautkan',
    agency_label9: 'Info',
    agency_label10: 'Tips Penandatanganan'
  }
}
