export default {
  medal: {
    medal: 'Koleksi Medali',
    take_off: 'Telah Digunakan',
    wear: 'Gunakan',
    get_time: 'Waktu',
    remove_label: 'Apakah Anda yakin untuk menghapus Medali?',
    wear_label: 'Apakah Anda yakin ingin menggunakan medali ini? Memakai medali ini akan mengubah medali yang sedang Anda pakai',
    remove_title: 'Hapus Medali',
    wear_title: 'Penggantian Medali'
  }
}
