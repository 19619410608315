export default {
  data: {
    // 世界账号上传国家
    world_countrys: [{
      name: 'ID',
      fullName: 'Indonesia'
    }, {
      name: 'ZA',
      fullName: 'South Africa'
    }, {
      name: 'MY',
      fullName: 'malaysia'
    }, {
      name: 'PH',
      fullName: 'Philippines'
    }, {
      name: 'RU',
      fullName: 'Russia'
    }, {
      name: 'BD',
      fullName: 'Bangladesh'
    }, {
      name: 'EG',
      fullName: 'Egypt'
    }],
    // ogc 世界账号上传国家
    ogc_world_countrys: [{
      name: 'ID',
      fullName: 'Indonesia'
    }, {
      name: 'ZA',
      fullName: 'South Africa'
    }, {
      name: 'MY',
      fullName: 'malaysia'
    }, {
      name: 'PH',
      fullName: 'Philippines'
    }, {
      name: 'RU',
      fullName: 'Russia'
    }, {
      name: 'BD',
      fullName: 'Bangladesh'
    }, {
      name: 'EG',
      fullName: 'Egypt'
    }],
    // ogc 世界账号上传语言
    ogc_world_language: [{
      nameEn: 'en',
      nameEnFull: 'english'
    }, {
      nameEn: 'hi',
      nameEnFull: 'hindi'
    }, {
      nameEn: 'ta',
      nameEnFull: 'tamil'
    }, {
      nameEn: 'te',
      nameEnFull: 'telugu'
    }, {
      nameEn: 'kn',
      nameEnFull: 'kannada'
    }, {
      nameEn: 'ml',
      nameEnFull: 'malayalam'
    }, {
      nameEn: 'mr',
      nameEnFull: 'marathi'
    }, {
      nameEn: 'pa',
      nameEnFull: 'punjabi'
    }, {
      nameEn: 'id',
      nameEnFull: 'indonesian'
    }, {
      nameEn: 'zu',
      nameEnFull: 'zulu'
    }, {
      nameEn: 'af',
      nameEnFull: 'afrikaans'
    }, {
      nameEn: 'xh',
      nameEnFull: 'xhosa'
    }, {
      nameEn: 'ms',
      nameEnFull: 'malay'
    }, {
      nameEn: 'tl',
      nameEnFull: 'filipino'
    }, {
      nameEn: 'ru',
      nameEnFull: 'russian'
    }, {
      nameEn: 'bn',
      nameEnFull: 'bengali'
    }, {
      nameEn: 'ar',
      nameEnFull: 'arabic'
    }, {
      nameEn: 'ur',
      nameEnFull: 'urdu'
    }, {
      nameEn: 'pa',
      nameEnFull: 'punjabi'
    }],
    phone_country_code: [{
      name: 'China',
      code: 'CN',
      value: '+86'
    }, {
      name: 'India',
      code: 'IN',
      value: '+91'
    }, {
      name: 'Egypt',
      code: 'EG',
      value: '+20'
    }, {
      name: 'Indonesia',
      code: 'ID',
      value: '+62'
    }, {
      name: 'United Kingdom',
      code: 'GB',
      value: '+44'
    }, {
      name: 'Russian Federation',
      code: 'RU',
      value: '+7'
    }, {
      name: 'Morocco',
      code: 'MA',
      value: '+212'
    }, {
      name: 'Philippines',
      code: 'PH',
      value: '+63'
    }, {
      name: 'Malaysia',
      code: 'MY',
      value: '+60'
    }, {
      name: 'Vietnam',
      code: 'VN',
      value: '+84'
    }, {
      name: 'Thailand',
      code: 'TH',
      value: '+66'
    }, {
      name: 'South Africa',
      code: 'ZA',
      value: '+27'
    }]
  }
};
