import axios from '@/utils/request';

/**
 * @name 未读评论数
 */
export const commentCount = () => {
  return axios.post('/shareit/pgc/comment/unread/count')
}

/**
 * @name 评论管理-评论分页
 */
export const pageComment = (params) => {
  return axios.post('/shareit/pgc/comment/list', { ...params })
}

/**
 * @name 评论管理-回复分页
 */
export const pageReply = (params) => {
  return axios.post('/shareit/pgc/comment/reply/list', { ...params })
}

/**
 * @name 视频详情评论分页
 */
export const videoPageComment = (params) => {
  return axios.post('/shareit/pgc/video/comment/list', { ...params })
}

/**
 * @name 视频详情回复分页
 */
export const videoPageReply = (params) => {
  return axios.post('/shareit/pgc/video/comment/reply/list', { ...params })
}
/**
 * @name 发布评论
 */
export const publishComment = (params) => {
  return axios.post('/shareit/pgc/comment/create', { ...params })
}

/**
 * @name 回复评论
 */
export const replyComment = (params) => {
  return axios.post('/shareit/pgc/comment/reply/create', { ...params })
}

/**
 * @name 删除评论
 */
export const deleteComment = (params) => {
  return axios.post('/shareit/pgc/comment/delete', { ...params })
}

/**
 * @name 点赞评论
 */
export const likeComment = (params) => {
  return axios.post('/shareit/pgc/comment/like', { ...params })
}

/**
 * @name 置顶评论
 */
export const topComment = (params) => {
  return axios.post('/shareit/pgc/comment/top', { ...params })
}

/**
 * @name 标记已读
 */
export const readComment = (params) => {
  return axios.post('/shareit/pgc/comment/read', params)
}
