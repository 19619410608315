<!-- setting password chenyh1 -->
<template>
  <div>
    <el-dialog :visible.sync="visible" :before-close="beforeClose" title="Reset your password" width="450px" custom-class="password-dialog">
      <el-form ref="form" :model="form" hide-required-asterisk>
        <template v-if="steps === 1">
          <el-form-item :rules="[{required: true, message: 'The password cannot be empty'}]" label="Current password" prop="oldPasswd">
            <el-input v-model="form.oldPasswd" show-password type="password" placeholder="Current password"></el-input>
          </el-form-item>
          <el-form-item :rules="[{required: true, message: 'The password cannot be empty'},{validator: checkPassword}]" label="New password" prop="newPasswd">
            <el-tooltip class="item" effect="light" placement="right">
              <el-input v-model="form.newPasswd" show-password type="password" placeholder="New password"></el-input>
              <div slot="content">
                1. The password must be between 8-20<br> characters in length.<br>
                2. Password must contain numbers, English<br> letters and special symbols.
              </div>
            </el-tooltip>
          </el-form-item>
          <el-form-item :rules="[{required: true, message: 'The password cannot be empty'},{validator: checkPassword}]" label="Confirm Password" prop="newPasswd2">
            <el-tooltip class="item" effect="light" placement="right">
              <el-input v-model="form.newPasswd2" show-password type="password" placeholder="Confirm Password"></el-input>
              <div slot="content">
                1. The password must be between 8-20<br> characters in length.<br>
                2. Password must contain numbers, English<br> letters and special symbols.
              </div>
            </el-tooltip>
          </el-form-item>
          <el-form-item class="submit">
            <el-button :loading="loading" @click="handleSubmit">Confirm</el-button>
          </el-form-item>
        </template>
        <transition name="fade">
          <el-form-item v-if="steps === 2" class="submit result">
            <div class="success">Password changed successfully.</div>
            <el-button type="primary" @click="handleLogout">Login</el-button>
          </el-form-item>
        </transition>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { changePassword, getPublicKey, logout } from '@/api/login';
import { JSEncrypt } from 'jsencrypt';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'StSetPassword',
  data() {
    return {
      visible: false,
      form: {
        oldPasswd: '',
        newPasswd: '',
        newPasswd2: ''
      },
      loading: false,
      steps: 1
    };
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    ...mapActions([
      'clearLoginInfo'
    ]),
    /** 触发显示 */
    show() {
      this.visible = true;
    },
    beforeClose(done) {
      if (this.steps === 1) {
        this.$refs.form.resetFields();
        done();
      } else {
        this.handleLogout();
      }
    },
    /** 校验密码 */
    checkPassword(rule, value, callback) {
      var regex = /^.{8,20}$/i;
      var regex1 = /[a-zA-Z]/;
      var regex2 = /\d/;
      var regex3 = /[\W_!@#$%^&*`~()-+=]/;
      if (!regex.test(value)) {
        callback(new Error('The password must be between 8-20 characters in length!'));
      } else if (!regex1.test(value) || !regex2.test(value) || !regex3.test(value)) {
        callback(new Error('Password must contain numbers, English letters and special symbols!'));
      } else if (this.form.newPasswd2 && this.form.newPasswd !== this.form.newPasswd2) {
        callback(new Error('The passwords do not match'));
      } else {
        callback();
      }
    },
    handleSubmit() {
      this.$refs.form.validate(vali => {
        if (vali) {
          this.loading = true;
          const params = {
            oldPasswd: this.form.oldPasswd,
            newPasswd: this.form.newPasswd
          };
          getPublicKey({ userName: this.userInfo.userName, from: 'inpasswd' }).then(re => {
            if (re.data.code === 0) {
              const encrypt = new JSEncrypt();
              encrypt.setPublicKey(re.data.data);
              params.oldPasswd = encrypt.encrypt(params.oldPasswd);
              params.newPasswd = encrypt.encrypt(params.newPasswd);
              changePassword(params).then(res => {
                res = res.data;
                if (res.code === 0) {
                  this.steps = 2;
                } else {
                  this.$message({
                    center: true,
                    type: 'error',
                    message: res.message || 'Server exception!'
                  });
                }
                this.loading = false;
              });
            } else {
              this.$message({
                center: true,
                type: 'error',
                message: re.data.message || 'Server exception!'
              });
              this.loading = false;
            }
          });
        }
      });
    },
    /** 退出登录 */
    handleLogout() {
      this.loading = true;
      logout().then(res => {
        this.clearLoginInfo();
        this.$router.push({
          path: '/login'
        });
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};

</script>
<style lang='scss' rel='stylesheet/sass' scoped>
.submit {
  margin-top: 40px;
  .el-button {
    width: 100%;
    background-image: linear-gradient(-90deg, #FF9090 0%, #EE1A1A 88%);
    box-shadow: 0 4px 13px 0 rgba(238,26,26,0.29);
    border-radius: 4px;
    border: none;
    font-family: Arial-BoldMT;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
  }
  &.result {
    .success {
      margin-bottom: 30px;
      padding-top:70px;
      background: url('~@/assets/images/pwd-success.png') no-repeat center top;
      font-family: ArialMT;
      font-size: 14px;
      color: #4A4A4A;
      text-align: center;
      line-height: 1;
    }
    .el-button {
      margin-bottom: 33px;
    }
  }
}
::v-deep {
  .password-dialog {
    .el-dialog__body {
      padding: 15px 75px;
      .el-form-item__label {
        font-family: Arial-BoldMT;
        font-size: 14px;
        color: #4A4A4A;
        line-height: 32px;
      }
    }
  }
}
.fade-enter-active {
  transition: opacity 0.8s;
}
.fade-enter {
  opacity: 0;
}
</style>
<style lang='scss' rel='stylesheet/sass'>

</style>
