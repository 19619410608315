export default {
  followers: {
    overview: 'Overview',
    profile: 'Profile',
    followers: 'Followers',
    poked: 'Poked',
    // overview
    followers_change: 'Followers Change',
    net_new_followers: 'Net New Followers',
    net_new_followers_help: 'Net new follower of yesterday=New follower of yesterday - Unfollower of yesterday,could be negative',
    data_update_time: 'Data Update Time',
    data_update_time_label: 'Update the earlier day’s data at 12:00 noon daily.',
    new_followers: 'New Followers',
    unfollow: 'Unfollow',
    follow: 'Follow',
    total_followers: 'Total Followers',
    total_followers_help: 'Includes followers from WATCHit',
    active_rate_followers: 'Active rate of followers',
    active_followers: 'Active followers',
    active_followers_help: 'Followers with watching behavior are active followers.',
    active_followers_ratio: 'Active followers ratio',
    net_growth: 'Net growth',
    new_concerns: 'New concerns',
    cancel_attention: 'Cancel attention',
    active_attention: 'Active attention',
    updating: 'Updating',
    // profile
    profile_label1: 'Follower characteristics',
    profile_label2: 'Device Info',
    profile_label3: 'Geographical distribution',
    profile_label4: 'The active time of followers',
    profile_label5: 'Mobile Phone Brand',
    profile_label6: 'Top 10 Brands',
    profile_label7: 'Data description',
    profile_label8: 'Data for the past 7 days will update every Monday at 12:00 AM.',
    // followers
    followers_label1: 'Includes followers from WATCHit',
    following: 'Following',
    videos: 'Videos',
    // poked
    poked_label1: 'Followers poked you and ask you to update',
    poked_label2: 'Include followers from WATCHit',
    poked_label3: 'Only display non-visitor followers'
  }
}
