export default {
  earnings: {
    earnings: 'Earnings',
    earnings_instructions: 'Earnings Instructions',
    yesterday_revenue: "Yesterday's Revenue",
    toatl_revenue: 'Toatl Revenue',
    available_balance: 'Available Balance',
    overall: 'Overall',
    video: 'Video',
    date: 'Date',
    cpm_daily_revenue: 'CPM Daily Revenue (USD)',
    event_revenue: 'Event Revenue (USD)',
    cover: 'Cover',
    daily_revenue: 'Daily Revenue (USD)'
  }
}
