function getCache(r) {
  let cache = {};
  r.keys().forEach(key => {
    cache = Object.assign(cache, r(key).default);
  });

  return cache;
}

const en = getCache(require.context('./en', true, /(?<!index)\.js$/));
const enViews = getCache(require.context('../views', true, /en\.js$/));
const id = getCache(require.context('./id', true, /(?<!index)\.js$/));
const idViews = getCache(require.context('../views', true, /id\.js$/));
export {
  en,
  enViews,
  id,
  idViews
};
