<template>
  <div class="st-header">
    <div class="st-logo">
      <span class="logoImg"><img src="@/assets/images/logo-header.png" alt="logo" />SHAREit WeMedia</span>
      <div class="inner-right">
        <st-language></st-language>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import StLanguage from './language';

export default {
  name: 'StHeaderNo',
  components: {
    StLanguage
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.st-header {
  height: 60px;
  background: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(223, 223, 223, 0.32);
}
.st-logo {
  margin: 0 auto;
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logoImg {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Arial;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    img {
      margin-right: 16px;
    }
  }
  .inner-right {
    display: flex;
    align-items: center;
  }
}
</style>
