import axios from '@/utils/request';

/**
 * @name 未读消息数
 */
export const msgCount = () => {
  return axios.post('/shareit/pgc/message/unread/count')
}

/**
 * @name 消息分页
 */
export const pageMsg = (params) => {
  return axios.post('/shareit/pgc/message/list', { ...params })
}

/**
 * @name 标记已读
 */
export const readMsg = (params) => {
  return axios.post('/shareit/pgc/message/read', params)
}
