export default {
  withdraw: {
    withdraw: 'Withdraw',
    available_balance: 'Available Balance',
    total_balance: 'Total Balance',
    withdraw_record: 'Withdraw Record',
    withdraw_amount: 'Withdraw Amount(USD)',
    tax: 'Tax(USD)',
    payment_amount: 'Payment Amount(USD/After-tax)',
    payment_status: 'Payment Status',
    status_list: [{
      label: 'Under process',
      value: 1
    }, {
      label: 'Payment Success',
      value: 2
    }, {
      label: 'Payment Failed',
      value: 3
    }],
    withdraw_label1: '(${num} has been frozen)'
  }
}
