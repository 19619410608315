export default {
  login: {
    login: 'Login',
    new_user: 'New user ?',
    sign: 'Sign Up',
    phone_login: 'Phone Login',
    account_login: 'Account Login',
    phone_number: 'Phone number',
    ver_code: 'Verification code',
    get_ver_code: 'Get a verification code',
    name_placeholder: 'Example: john@gmail.com',
    password: 'Password',
    pwd_placeholder: 'Your Password',
    rep_pwd_placeholder: 'Confirm password',
    forget_pwd: 'Forget Password?',
    name_required: 'User name is required',
    pwd_required: 'The password cannot be empty',
    vercode_required: 'Verification code cannot be empty, please fill in.',
    phone_required: 'Phone number is required',
    // register
    have_account: 'I have an account ?',
    resend: 'Resend',
    terms_privacy: 'I have read and agreed with the <a href="http://cdn.ushareit.com/shareit/w/pgc/tos.html" target="_blank">《Terms of Service》</a> and <a href="http://cdn.ushareit.com/shareit/w/pgc/privacy.html" target="_blank">《Privacy Policy》</a>',
    pwd_rule_text: '1. The password must be between 8-20<br />characters in length.<br />2. Password must contain numbers, English<br />letters and special symbols.',
    pwd_rule1: 'Password must be 8-20 characters.',
    pwd_rule2: 'Password must contain numbers, English letters and special symbols.',
    pwd_rule3: 'Those passwords didn\'t match.Try again.',
    email_required: 'The email cannot be empty',
    term_policy_required: 'You must read and agree to this Terms of Service and Privacy Policy.',
    // forget password
    retrieve_password: 'Retrieve password',
    email_label: 'Please enter the email account',
    email_placeholder: 'Enter the email account',
    email_format: 'Mailbox format error',
    next: 'Next',
    email_send_success: 'SHAREit just sent an email with a verification code to',
    new_pwd: 'New Password',
    confirm: 'Confirm',
    reset_pwd_success: 'Password changed successfully.'
  }
}
