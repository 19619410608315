<!-- feedback -->
<template>
  <div class="feedback-content">
    <el-dialog
      :visible.sync="visible"
      title="Feedback"
      :close-on-click-modal="false"
      :before-close="handleCancel"
      top="15vh"
      width="550px"
      append-to-body
      class="feedback-dialog"
    >
      <el-form
        ref="form"
        v-loading="saveLoading"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <!-- product -->
        <el-form-item prop="classify_id" required label="Function">
          <el-select v-model="form.classify_id" placeholder="Select" @change="functionChange">
            <el-option
              v-for="item in classifyIds"
              :key="item.classifyId"
              :value="item.classifyId"
              :label="item.name"
            /></el-select>
        </el-form-item>
        <!-- Description -->
        <el-form-item label="Description" prop="message" required>
          <!-- popover -->
          <el-popover placement="bottom-start" width="375" trigger="hover">
            <div class="popover-area">
              <div class="item">
                <div class="title">Try to be simple and clear</div>
                <div class="error">
                  <svg-icon icon-class="error" />I have a few suggestions for
                  optimizing the ***(name the function).
                </div>
                <div class="success">
                  <svg-icon icon-class="success" />
                  I have a few optimization suggestions for this feature.
                </div>
              </div>
              <div class="item">
                <div class="title">
                  Offer necessary and detailed info
                </div>
                <div class="error">
                  <svg-icon icon-class="error" />How to create a video?
                </div>
                <div class="success">
                  <svg-icon icon-class="success" />
                  What is the basic knowledge for creating a video?
                </div>
              </div>
            </div>
            <svg-icon slot="reference" icon-class="help" class="popover-icon" />
          </el-popover>
          <el-input
            v-model="form.message"
            type="textarea"
            placeholder="We are glad to receive your suggestion, please explain the issue here."
            maxlength="500"
            show-word-limit
          />
        </el-form-item>
        <!-- picture upload-->
        <el-form-item>
          <div class="picture-area">
            <el-upload
              ref="upload"
              name="files"
              :action="action"
              :headers="uploadHeader"
              :file-list="fileList"
              :data="uploadParams"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-progress="uploadProgress"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :disabled="pictureCard.length === 5"
            >
              <el-button
                :loading="uploadLoading"
                :disabled="pictureCard.length === 5"
                class="upload-button"
                :class="{ uploadDisabled: pictureCard.length === 5 }"
                @click="handleUpload"
              >{{ uploadImgBtnText }}</el-button>
            </el-upload>
            <div class="tip">jpg/gif/png, max size 2M and 5 images.</div>
          </div>
          <!-- picture card -->
          <div v-if="pictureCard.length" class="picture-card">
            <div
              v-for="(picSrc, index) in pictureCard"
              :key="index"
              class="picture-wrap"
            >
              <img :src="picSrc" class="picture" />
              <div class="shade">
                <i class="el-icon-close delete" @click="handleDelete(index)" />
              </div>
            </div>
          </div>
        </el-form-item>
        <!-- phone number -->
        <el-form-item label="Contact Information" prop="phone_number">
          <div class="phone-area">
            <el-select
              v-model="countryCode"
              no-data-text="No data"
              filterable
              class="country-code"
            >
              <el-option
                v-for="(item, idx) in $t('data.phone_country_code')"
                :key="idx"
                :label="item.value"
                :value="item.value"
                style="width:200px;"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.value
                }}</span>
              </el-option>
            </el-select>
            <el-input
              v-model.trim="form.phone_number"
              type="number"
              clearable
              placeholder="You may offer phone number for further contact."
            />
          </div>
          <div class="tip email-tip">
            For further contact or suggestion,please email
            <a
              href="mailto:wemedia@ushareit.com"
              target="_blank"
            >wemedia@ushareit.com</a>
          </div>
        </el-form-item>
        <el-form-item class="button-area">
          <el-button
            class="submit"
            :disabled="uploadLoading"
            @click.prevent="handleSubmit"
          >
            Submit
          </el-button>
          <el-button
            :disabled="uploadLoading"
            class="cancel"
            @click.prevent="handleCancel"
          >Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveFeedbackInfo,
  uploadFeedbackImg,
  getClassify
} from '@/api/feedback'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      rules: {
        classify_id: [
          { required: true, message: "You can't leave the Function empty" }
        ],
        message: [
          { required: true, message: "You can't leave the Description empty" }
        ]
      },
      visible: false,
      uploadLoading: false,
      saveLoading: false,
      classifyIds: [],
      form: {
        appId: 'wemedia',
        userId: '',
        classify_id: '',
        country: '',
        region: '',
        contact_info: '',
        phone_number: '',
        lang: '',
        portal: '',
        message: '',
        image_urls: ''
      },
      pictureCard: [],
      countryCode: '+62',
      action: '',
      fileList: [],
      uploadHeader: {
        Authentication: ''
      },
      uploadParams: {
        file_type: '1'
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    uploadImgBtnText() {
      const len = this.pictureCard.length
      if (len === 0) {
        return 'Add screenshots'
      } else if (len === 1) {
        return '1 picture added'
      } else {
        return `${len} pictures added`
      }
    }
  },
  methods: {
    show() {
      this.visible = true
      this.action = uploadFeedbackImg
      this.uploadHeader.Authentication = localStorage.getItem('LOGIN_TOKEN')
      this.initUserInfo()
      this.initClassify()
    },
    initUserInfo() {
      const { ndAuthorId, country, lang } = this.userInfo
      this.form.userId = ndAuthorId
      this.form.country = country
      this.form.lang = lang
      if (country) {
        const _item = this.$t('data.phone_country_code').filter(
          (item) => item.code === country
        )
        this.countryCode = _item.value || '+62'
      }
    },
    initClassify() {
      const params = {
        appId: 'wemedia'
      }
      getClassify(params).then((res) => {
        const data = res.data
        if (data.result_code === 200) {
          this.classifyIds = data.data
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$report(`/wm_feedback/submit/x`);
          this.saveLoading = true
          const params = Object.assign({}, this.form)
          params.image_urls = this.pictureCard.join('|')
          if (this.form.phone_number) {
            params.phone_number = this.countryCode + params.phone_number
          }
          saveFeedbackInfo(params).then((res) => {
            this.saveLoading = false
            if (res.data.result_code === 200) {
              this.handleCancel()
              this.$message.success('Thank you for the feedback.')
            } else {
              this.$message.error('Submission failed, please try again later.')
            }
          })
        }
      })
    },
    handleCancel() {
      if (this.uploadLoading || this.saveLoading) {
        this.$message.warning('Submission underway, please wait')
        return
      }
      this.$refs.form.resetFields()
      this.pictureCard = []
      this.countryCode = '+62'
      this.visible = false
    },
    beforeUpload(file) {
      const type = file.type
      const isImage =
				type === 'image/jpeg' || type === 'image/png' || type === 'image/gif'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isImage) {
        this.$message.warning(
          'Upload picture should only be in jpg / gif / png'
        )
      }
      if (!isLt2M) {
        this.$message.warning('Upload picture size must less than 2MB!')
      }
      return isLt2M && isImage
    },
    uploadProgress() {
      this.uploadLoading = true
    },
    uploadSuccess(res) {
      this.uploadLoading = false
      this.pictureCard.push(res.data.file_url)
    },
    uploadError() {
      this.uploadLoading = false
      this.$message.error('Upload picture failed, please try again.')
    },
    handleDelete(index) {
      this.pictureCard.splice(index, 1)
    },
    functionChange(val) {
      this.$report(`/wm_feedback/function/x`, {
        functien_id: val
      });
    },
    handleUpload() {
      this.$report(`/wm_feedback/add/x`);
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/sass" scoped>
.el-form {
	width: 490px;
	margin: 0 auto;
	.el-select {
		width: 100%;
	}
	.el-form-item {
		margin-bottom: 24px;
		&:nth-child(2) {
			margin-bottom: 15px;
		}
		&:nth-child(4) {
			margin-bottom: 34px;
		}
		&:nth-child(5) {
			margin-bottom: 0;
		}
	}
	::v-deep {
		.el-form-item__label {
			height: 26px;
			line-height: 26px;
			color: #4a4a4a;
			font-size: 14px;
			font-family: Roboto-Regular;
			margin-bottom: 5px;
			padding: 0;
		}
		.el-textarea .el-textarea__inner {
			resize: none;
			height: 160px;
			color: #4a4a4a;
			font-size: 14px;
			font-family: Roboto-Regular;
			line-height: 20px;
			box-sizing: border-box;
			border: 0;
			padding: 7px 10px 3px 10px;
		}
		.el-textarea {
			border: 1px solid #e0e0e0;
			border-radius: 4px;
			.el-input__count {
				display: block;
				background: #fff;
				height: 20px;
				line-height: 20px;
				text-align: right;
				position: relative;
				margin-top: 0px;
				margin-left: 10px;
			}
		}
	}
	.upload-button {
		margin-right: 8px;
		width: 131px;
		height: 29px;
		line-height: 29px;
		background: #f7f8f9;
		border: 1px solid #e6eaee;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: #949494;
		font-family: Roboto-Regular;
	}
	.uploadDisabled {
		color: #c0c4cc;
		cursor: not-allowed;
		background-image: none;
		background-color: #fff;
		border-color: #ebeef5;
	}
	::-webkit-scrollbar {
		width: 4px;
		height: 55px;
	}
	::-webkit-scrollbar-track {
		background-color: #fff;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 2.5px;
		background-color: #e2e2e2;
	}
	.popover-icon {
		position: absolute;
		top: -25px;
		left: 92px;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}
}
.popover-area {
	padding: 5px 0;
	.item {
		font-family: Roboto-Regular;
		.title {
			font-size: 14px;
			color: #4a4a4a;
			line-height: 16px;
			margin-bottom: 5px;
		}
		.success,
		.error {
			font-size: 12px;
			color: #949494;
			line-height: 20px;
		}
		.svg-icon {
			flex: 0 0 10px;
			width: 10px;
			height: 10px;
			margin-right: 5px;
		}
		&:first-child {
			margin-bottom: 16px;
		}
	}
}
.tip {
	height: 14px;
	line-height: 14px;
	color: #949494;
	font-size: 12px;
	font-family: Roboto-Regular;
}
.email-tip {
	margin-top: 10px;
	a {
		color: #0092ff;
	}
}

.picture-area {
	display: flex;
	align-items: center;
}

.picture-card {
	margin-top: 10px;
	height: 90px;
	display: flex;
	.picture-wrap {
		width: 90px;
		height: 90px;
		border-radius: 4px;
		overflow: hidden;
		margin-right: 10px;
		position: relative;
		cursor: pointer;
		.picture {
			width: 100%;
			height: 100%;
		}
		&:last-child {
			margin-right: 0;
		}
		.shade {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			opacity: 0;
			transition: opacity 0.3s;
			.delete {
				width: 10px;
				height: 10px;
				position: absolute;
				top: 8px;
				right: 8px;
				z-index: 1000;
				color: #fff;
			}
		}
		&:hover {
			.shade {
				opacity: 1;
			}
		}
	}
}
.phone-area {
	display: flex;
	.country-code {
		flex: 0 0 86px;
		width: 86px;
		color: #4a4a4a;
		font-family: Roboto-Regular;
		border-radius: 2px;
		margin-right: 6px;
	}
}
.button-area {
	text-align: center;
	.el-button {
		width: 86px;
		height: 36px;
		border: none;
		border-radius: 4px;
		font-family: Roboto-Bold;
		margin: 0 12px;
	}
	.submit {
		color: #ffffff;
		background: #ff4900;
	}
	.cancel {
		color: #4a4a4a;
		background: #f6f6f6;
	}
}
</style>
<style lang="scss" rel="stylesheet/sass">
.feedback-dialog .el-dialog {
	.el-dialog__header {
		border-bottom: 0;
		color: #4a4a4a;
		font-size: 16px;
		font-weight: bold;
		font-family: Roboto-Bold;
	}
	.el-dialog__body {
		padding-top: 10px;
		padding-bottom: 40px;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
}
.el-popover[x-placement^='bottom'] {
	margin-left: -6px;
	margin-top: 10px;
}
</style>
