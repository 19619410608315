export default {
  guide: {
    know: 'I know',
    post: 'Click here to upload your video',
    analytics: 'Data about your videos can be checked here',
    functions: 'Apply and pass the audit, you will be able to use certain functions like Earning',
    academy: 'Check out the tutorial videos and use a guide for this platform. We hope it helps you work out better content in the future.',
    followers: 'You can check data and profile of your followers to understand the preferences of the audience.'
  }
}
