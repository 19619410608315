export default {
  analytics: {
    overview: 'Overview',
    detailed: 'Detil',
    recommend: 'Rekomendasi',
    plays: 'Plays',
    table_title: 'Tabel Data Tren',
    title: 'Judul',
    publish_time: 'Waktu Publikasi',
    data_label1: 'Anda belum mengirimkan video'
  }
}
