export default {
  settings: {
    // Personal information
    personal_info: 'Informasi Personal',
    id: 'ID',
    user_name: 'Nama Pengguna',
    icon: 'Ikon',
    nick_name: 'nickname',
    introduction: 'Perkenalan',
    country: 'Negara',
    language: 'Bahasa',
    field: 'Bidang',
    // Contact information
    contact_info: 'Informasi Kontak',
    email: 'Email',
    email_verification: 'Verifikasi Email',
    verification_code: 'Kode Verifikasi',
    get_verification_code: 'Dapatkan Kode Verifikasi',
    password_tip: 'Jika Anda ingin masuk ke WeMedia menggunakan email ini, silakan gunakan password',
    phone: 'Telepon',
    phone_number: 'Nomer Telepon',
    phone_verification: 'Verifikasi Nomor Telepon',
    // Bound App
    bound_app: 'Aplikasi Terkait',
    bind: 'Tautkan',
    // Add a credit or debit card
    card_title: 'Tambahkan kartu debit dan kredit',
    bank_name: 'Nama Bank',
    bank_country: 'Negara Bank',
    bank_no: 'Nomor Rekening',
    account_name: 'Nama Pemilik Rekening',
    country_region: 'Area/Negara',
    province: 'Provinsi',
    city: 'Kota',
    address: 'Alamat',
    address_placeholder: 'contoh: **Jalan, Nomor Rumah, Kelurahan, Kecamatan, Kota, Provinsi, Kode Pos',
    // Certification information
    certification_info: 'Informasi Sertifikasi',
    operation_tips: 'Tips Penggunaan',
    youtube_url_title: 'Sumber URL YouTube',
    youtube_certificate_title: 'Unggah Sertifikasi YouTube',
    facebook_url_title: 'Sumber URL Facebook',
    facebook_certificate_title: 'Unggah Sertifikasi Facebook',
    tiktok_url_title: 'Sumber URL TikTok',
    tiktok_certificate_title: 'Unggah Sertifikasi TikTok',
    snackvideo_url_title: 'Sumber URL SnackVideo',
    snackvideo_certificate_title: 'Unggah Sertifikasi SnackVideo',
    under_process: 'Dalam Proses',
    connect: 'Hubungkan',
    details: 'Detil',
    deny_reasons: 'Alasan Penolakan'

  }
}
