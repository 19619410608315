export default {
  followers: {
    overview: 'Rangkuman',
    profile: 'Profil',
    followers: 'Pengikut',
    poked: 'Colek',
    // overview
    followers_change: 'Perubahan Followers',
    net_new_followers: 'Jumlah Pengikut Baru',
    net_new_followers_help: 'Jumlah pengikut baru kemarin = Follower baru kemarin - Tidak mengikuti kemarin (Bisa negatif)',
    data_update_time: 'Waktu Update Data',
    data_update_time_label: 'Update data sebelumnya pada jam 12:00 WIB',
    new_followers: 'Pengikut Baru',
    unfollow: 'Tidak Mengikuti',
    follow: 'Ikuti',
    total_followers: 'Total Pengikut',
    total_followers_help: 'Termasuk followers dari WATCHit',
    active_rate_followers: 'Rata-rata waktu aktif pengikut',
    active_followers: 'Pengikut Aktif',
    active_followers_help: 'Pengikut yang ditampilkan dengan perilaku menonton adalah pengikut aktif',
    active_followers_ratio: 'Rasio Pengikut aktif',
    net_growth: 'Nilai Pertumbuhan',
    new_concerns: 'Perhatian',
    cancel_attention: 'Batalkan Perhatian',
    active_attention: 'Perhatian Aktif',
    updating: 'Sedang Memperbarui',
    // profile
    profile_label1: 'Karakteristik Pengikut',
    profile_label2: 'Info Ponsel',
    profile_label3: 'Distribusi Geografis',
    profile_label4: 'Waktu Aktif Pengikut',
    profile_label5: 'Merek Ponsel',
    profile_label6: '10 Brand Terbaik',
    profile_label7: 'Deskripsi Data',
    profile_label8: 'Data 7 hari sebelumnya akan diupdate setiap Senin pukul 12.00 AM',
    // followers
    followers_label1: 'Termasuk pengikut dari WATCHit',
    following: 'Mengikuti',
    videos: 'Video',
    // poked
    poked_label1: 'Pengikut mencolek Anda dan meminta Anda memperbarui konten',
    poked_label2: 'Termasuk pengikut dari WATCHit',
    poked_label3: 'Hanya memunculkan data pengikut non-visitor'
  }
}
