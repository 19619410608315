export default {
  route: {
    home: 'Home',
    post: 'Post',
    content: 'Content',
    comments: 'Comments',
    analytics: 'Analytics',
    settings: 'Settings',
    sync: 'Video Sync',
    functions: 'Functions',
    earnings: 'Earnings',
    withdraw: 'Withdraw',
    academy: 'Creator Academy',
    followers: 'Followers',
    targetCenter: 'TargetCenter',
    notice_centre: 'Notice Centre'
  }
}
