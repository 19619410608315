export default {
  post: {
    video: 'Video',
    file_upload: 'Klik upload dan tarik dokumen ke dalam area ini',
    reupload: 'Unggah ulang',
    upload_rule: '<span>Size: &lt; 10GB</span><span>Resolusi: &gt; 240P</span><span>Rasio Aspek: ≥1</span><span>10 ≤ time ≤1h</span>',
    post_rule_title1: 'Rekomendasi format',
    post_rule_label1: 'Mp4 is rekomendasi',
    post_rule_title2: 'Rekomendasi Definisi',
    post_rule_title3: 'Rekomendasi Ukuran',
    post_rule_label3: 'Kurang dari 2G, dalam 10 menit',
    title: 'Judul',
    title_placeholder: 'Video Anda membutuhkan judul dan terbatas hingga 120 karakter.',
    description: 'Deskripsi',
    description_placeholder: 'Beri tahu penonton mengenai video Anda.',
    language: 'Bahasa',
    label: 'Label',
    tag: 'Tag',
    horizontal_cover: 'Cover Horizontal',
    original_video: 'Video Original',
    original: 'Original',
    non_original: 'Bukan-Original',
    schedule: 'Jadwalkan',
    schedule_tip: 'Pilih tanggal untuk mengubah video menjadi publik.',
    publish: 'Kirim',
    save_draft: 'Simpan sebagai draft',
    change: 'Perubahan',
    cover_tip: 'Pemberitahuan: Cover foto yang berukuran besar dan jelas akan membantu untuk mewakili konten.',
    required: 'Dibutuhkan',
    save_success: 'Berhasil Disimpan!',
    join_event: 'Gabung Acara',
    end_in: 'Acara selesai dalam waktu',
    day: 'hari',
    reward: 'Keuntungan',
    joined: 'join',
    activity_label1: 'Durasi Video yang diperlukan adalah {min} ~ {max} detik untuk acara ini.',
    activity_label2: 'Pilih label {label}',
    details: 'Detil'
  }
}
