// 将时间戳转换成日期时间格式
export function timeFormat(time, fmt) {
  if (time === undefined || time === null) {
    return '';
  }
  fmt = fmt || 'yyyy-MM-dd hh:mm';
  time = time.toString();
  if (time.length === 10) {
    time = time * 1000;
  } else {
    time = time * 1;
  }

  const date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (const k in o) {
    const str = o[k] + '';
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

export function getParams(url, variable) {
  const query = url.substring(url.indexOf('?') + 1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}
