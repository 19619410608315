export default {
  medal: {
    medal: 'Medal Wall',
    take_off: 'Remove',
    wear: 'Has been use',
    get_time: 'Unlock Time',
    remove_label: 'Are you sure to remove the medal?',
    wear_label: 'Are you sure to wear the medal? Wearing the medal will replace the one you are using.',
    remove_title: 'Remove the Medal',
    wear_title: 'Replacement Medal'
  }
}
