export default {
  analytics: {
    overview: 'Overview',
    detailed: 'Detailed',
    recommend: 'Recommend',
    plays: 'Plays',
    table_title: 'Data trend table',
    title: 'Title',
    publish_time: 'Publish Time',
    data_label1: "You haven't posted a video yet"

  }
}
