<template>
  <el-scrollbar class="main-scrollbar">
    <div id="main">
      <div class="header">
        <st-header></st-header>
      </div>
      <div class="home">
        <div class="main-warpper">
          <div class="slider">
            <st-slider></st-slider>
          </div>
          <div v-if="$route.name === 'home'" class="content-home">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
          <el-scrollbar v-else class="content">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.fullPath"></router-view>
            </transition>
          </el-scrollbar>
        </div>
      </div>
      <div class="footer">
        <st-footer></st-footer>
      </div>
      <div v-if="guideModal" class="guide-modal"></div>
    </div>
  </el-scrollbar>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import Header from '@/components/header/index';
import Footer from '@/components/footer/index';
import Slider from '@/components/slider/index';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'StMain',
  components: {
    'st-header': Header,
    'st-footer': Footer,
    'st-slider': Slider
  },
  data() {
    return {
      lang: 'en'
    };
  },
  computed: {
    ...mapState('guide', ['guideModal'])
  },
  created() {
    this.initGuide();
  },
  methods: {
    ...mapActions('guide', ['initGuide'])
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.main-scrollbar {
  height: 100vh;
  width: 100vw;
  background: #f8f8f8;
  ::v-deep {
    .el-scrollbar__wrap {
      &::-webkit-scrollbar {
        display: none;
      }
      .el-scrollbar__view {
        min-height: 100%;
        display: flex;
      }
    }
  }
}
#main {
  background: #f8f8f8;
  width: 100%;
  min-height: 100%;
  min-width: 1240px;
  display: flex;
  flex-flow: column;
  position: relative;
  .guide-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
}
.header {
  height: 60px;
}
.home {
  flex: 1;
  position: relative;
  .main-warpper {
    position: relative;
    width: 1200px;
    min-height: calc(100vh - 220px);
    padding: 20px;
    margin: auto;
    overflow: hidden;
    .slider {
      float: left;
      width: 230px;
      border-radius: 6px;
      margin-right: 20px;
      background: #ffffff;
    }
    .content {
      position: absolute;
      left: 270px;
      width: 950px;
      height: calc(100% - 40px);
      border-radius: 6px;
      ::v-deep {
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
        .el-scrollbar__view > :first-child {
          margin-bottom: 0 !important;
        }
      }
    }
    .content-home {
      width: 950px;
      border-radius: 6px;
      overflow: hidden;
    }
  }
}

.footer {
  height: 120px;
}
</style>
