import Vue from 'vue';
import store from '../store';
import { getParams } from './index'
const perf = Vue.prototype.$perf

/**
 * @name 日志上报
 * @param {*} key
 * @param {*} params
 */
export default function(key, params) {
  let referrer = '';
  try {
    referrer = getParams(window.location.href, 'referrer') || sessionStorage.getItem('WeMedia_REFERRER');
    if (referrer) {
      referrer = JSON.parse(decodeURIComponent(atob(decodeURIComponent(referrer))));
      referrer.um = referrer.um ? JSON.parse(referrer.um) : {}
    }
  } catch (error) {
    referrer = '';
  }
  // report上报
  try {
    if (perf) {
      perf.report({
        'uid': store.getters.userInfo ? store.getters.userInfo.userid : '',
        time: new Date().getTime(),
        aid: referrer && referrer.um.activityId || getParams(window.location.href, 'aid') || '',
        cid: referrer && referrer.us || '',
        iid: referrer && referrer.um.inviteUserId || '',
        ...params,
        pve_cur: key
      })
    }
  } catch (error) {
    console.log(error);
  }
}
