// user
import * as types from '../mutations';
import router from '@/router';
import { asyncRouterMap, errorRouter } from '@/router';
import store from '@/store';
import { msgCount } from '@/api/message';
import { commentCount } from '@/api/comment';
import Cookies from 'js-cookie';
import { pokedCount } from '@/api/followers';
import { followerCount } from '@/api/followers';

const defaultMenuList = [
  // {
  //   id: 'home',
  //   title: 'Home'
  // }, 
  {
    id: 'post',
    title: 'Post'
  },
  {
    id: 'content',
    title: 'Content'

  },
  // {
  //   id: 'comments',
  //   title: 'Comments'

  // },
  // {
  //   id: 'analytics',
  //   title: 'Analytics'
  // },
  // {
  //   id: 'followers',
  //   title: 'Followers'
  // },
  // {
  //   id: 'sync',
  //   title: 'Video Sync'
  // }
];
const earningMenuList = [
  // {
  //   id: 'targetCenter',
  //   title: 'TargetCenter'
  // },
  // {
  //   id: 'functions',
  //   title: 'Functions'
  // },
  // {
  //   id: 'earnings',
  //   title: 'Earnings'
  // },
  // {
  //   id: 'withdraw',
  //   title: 'Withdraw'
  // }
];
const defaultMenuList2 = [
  // {
  //   id: 'academy',
  //   title: 'Creator Academy'
  // },
  {
    id: 'settings',
    title: 'Settings'
  }
];
// state
const state = {
  clientWidth: 0,
  clientHeight: 0,
  menuList: [],
  // 未读消息
  messageCount: 0,
  // 未读评论
  commentCount: 0,
  language: Cookies.get('language') || 'en',
  // 催更数
  pokedCount: 0,
  // 新关注数
  followerCount: 0

};

// getters

const getters = {
  clientWidth: (state) => state.clientWidth,
  clientHeight: (state) => state.clientHeight,
  menuList: (state) => state.menuList,
  messageCount: (state) => state.messageCount,
  commentCount: (state) => state.commentCount,
  language: (state) => state.language,
  pokedCount: (state) => state.pokedCount,
  followerCount: (state) => state.followerCount
};

// mutations

const mutations = {

  [types.SET_CLIENT_SIZE](state, { size }) {
    state.clientHeight = size.height;
    state.clientWidth = size.width;
  },
  [types.SET_MENU_LIST](state, menuList) {
    state.menuList = menuList;
  },
  SET_MESSAGE_COUNT(state, count) {
    state.messageCount = count;
  },
  SET_COMMENT_COUNT(state, count) {
    state.commentCount = count;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_POKED_COUNT(state, count) {
    state.pokedCount = count;
  },
  SET_FOLLOWER_COUNT(state, count) {
    state.followerCount = count;
  }
};

// actions

const actions = {
  setClientSize({ commit, state }, size) {
    commit(types.SET_CLIENT_SIZE, { size });
  },
  setMenuList({ commit, state }, list) {
    commit(types.SET_MENU_LIST, list);
  },
  InitRouters({ commit }) {
    const level = store.getters.userInfo.level;
    const country = store.getters.userInfo.country;
    if (country === 'ID' && level >= 0 && level <= 9) {
      commit(types.SET_MENU_LIST, [...defaultMenuList, ...earningMenuList, ...defaultMenuList2]);
      router.addRoutes([...asyncRouterMap, ...errorRouter]);
    } else {
      commit(types.SET_MENU_LIST, [...defaultMenuList, ...defaultMenuList2]);
      router.addRoutes(errorRouter);
    }
  },
  InitMessage({ commit }) {
    // msgCount().then(res => {
    //   res = res.data;
    //   if (res.code === 0) {
    //     commit('SET_MESSAGE_COUNT', res.data)
    //   }
    // })
  },
  InitComment({ commit }) {
    // commentCount().then(res => {
    //   res = res.data;
    //   if (res.code === 0) {
    //     commit('SET_COMMENT_COUNT', res.data)
    //   }
    // })
  },
  SetLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
  InitPoked({ commit }) {
    // pokedCount().then(res => {
    //   res = res.data;
    //   if (res.code === 0) {
    //     commit('SET_POKED_COUNT', res.data)
    //   }
    // })
  },
  InitFollowers({ commit }) {
    // followerCount().then(res => {
    //   res = res.data;
    //   if (res.code === 0) {
    //     commit('SET_FOLLOWER_COUNT', res.data)
    //   }
    // })
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
