/**
 * Created by superman on 18/4/23.
 * http配置
 */

import axios from 'axios';
import router from '@/router';
import store from '@/store';

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_API_PATH, // url = base url + request url
  withCredentials: true // send cookies when cross-domain requests
  // timeout: 15000 // request timeout
});

// http request 拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('LOGIN_TOKEN') || '';
    config.headers['token'] = token;
    try {
      let referrer = sessionStorage.getItem('WeMedia_REFERRER');
      if (referrer) {
        referrer = atob(referrer);
        if (config.method === 'get') {
          config.params = {
            referrer: referrer,
            ...config.params
          }
        } else {
          config.url += (config.url.indexOf('?') === -1 ? '?' : '&') + 'referrer=' + referrer;
        }
      }
    } catch (error) {
      console.log(error)
    }
    return config;
  },
  err => {
    Promise.reject(err);
  });

// http response 拦截器
service.interceptors.response.use(
  response => {
    // console.log(response);
    const code = response.data.code;
    if (code !== undefined) {
      switch (code) {
        case 204:
          store.dispatch('clearLoginInfo');
          store.dispatch('closeUploadCoverDialog');
          router.replace({
            path: '/login'
          });
      }
    }
    return response;
  },
  error => {
    console.log(error)
    if (error && error.response && ((error.response.status && error.response.status === 503) || (error.response.data && error.response.data.code && error.response.data.code === 204))) {
      router.replace({ path: '/login' });
      return Promise.reject(error);
    } else {
      router.push({ path: '/error' });
      return Promise.reject(error);
    }
    // return Promise.reject(error);
  });

export default service;
