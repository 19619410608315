export default {
  content: {
    all: 'All',
    publish: 'Publish',
    draft: 'Draft',
    pending: 'Pending',
    deny: 'Deny',
    offline: 'Offline',
    transcoding: 'Transcoding',
    failure: 'Transcoding failure',
    repetition: 'Video repetition',
    published: 'Published',
    under_appeal: 'Under Appeal',
    appeal_through: 'Appeal through',
    appeal_failed: 'Appeal Failed',
    original: 'Original',
    recommend: 'Recommend',
    clicks: 'Clicks',
    comments: 'Comments',
    cover: 'Cover',
    comment_placeholder: 'Add a public comment...',
    load_comments: 'Load more comments',
    no_comments: 'No more comments',
    comments_label1: 'The comment feature will be available after the official release.'
  }
}
