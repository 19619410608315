import Main from '@/components/layout/Main.vue';
const Other = () => import('@/components/layout/Other');
// const Earnings = () => import('@/views/earnings/index');
// const Withdraw = () => import('@/views/withdraw');
const EarningsAbout = () => import('@/views/earnings/about');
// const Functions = () => import('@/views/functions');
const OriginalAbout = () => import('@/views/functions/originalAbout');
const AgencyAbout = () => import('@/views/functions/agencyAbout');
// const TargetCenter = () => import('@/views/targetCenter');

export default [
  {
    path: '/',
    component: Main,
    children: [
      // {
      //   path: '/targetCenter',
      //   component: TargetCenter,
      //   name: 'targetCenter'
      // },
      // {
      //   path: '/functions',
      //   component: Functions,
      //   name: 'functions'
      // },
      // {
      //   path: '/earnings',
      //   component: Earnings,
      //   name: 'earnings'
      // },
      // {
      //   path: '/withdraw',
      //   component: Withdraw,
      //   name: 'withdraw'
      // }
    ]
  },
  {
    path: '/',
    component: Other,
    children: [
      {
        path: '/earnings/about',
        component: EarningsAbout,
        name: 'earningsAbout'
      },
      {
        path: '/original/about',
        component: OriginalAbout,
        name: 'originalAbout'
      },
      {
        path: '/agency/about',
        component: AgencyAbout,
        name: 'agencyAbout'
      }
    ]
  }
];
