export const SET_CLIENT_SIZE = 'SET_CLIENT_SIZE';
export const TOGGLE_UPLOAD_COVER_DIALOG = 'TOGGLE_UPLOAD_COVER_DIALOG';
export const CLOSE_UPLOAD_COVER_DIALOG = 'CLOSE_UPLOAD_COVER_DIALOG';
export const SET_MENU_LIST = 'SET_MENU_LIST';
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const SET_FACEBOOK_INFO = 'SET_FACEBOOK_INFO';
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';

