export default {
  comments: {
    comments: 'Comments',
    creator: 'Creator',
    pinned: 'Pinned',
    cancel_pinned: 'Cancel Pinned',
    like: 'Like',
    reply: 'Reply',
    cancel_reply: 'Cancel reply',
    pinned_title: 'Are you sure to top pin the comment?',
    cancel_pinned_title: 'Are you sure to cancel pinned?',
    sort_list: [{
      label: 'Newest',
      value: 'latest'
    }, {
      label: 'Most likes',
      value: 'likeCount'
    }, {
      label: 'Most Replied',
      value: 'replyCount'
    }],
    status_list: [{
      label: 'All',
      value: ''
    }, {
      label: 'Reply',
      value: 'replied'
    }, {
      label: 'No reply',
      value: 'noReply'
    }]
  }
}
