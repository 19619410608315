<template>
  <div class="st-header">
    <div class="st-logo">
      <span class="logoImg" @click="handleClickTo"
        ><img src="@/assets/images/logo-header.png" alt="logo" />SHAREit
        WeMedia</span
      >
      <div class="inner-right">
        <st-language></st-language>
        <!-- <el-dropdown class="message" placement="bottom" @command="handleCommand">
          <el-badge :value="unreadCount" :max="99" :hidden="unreadCount === 0">
            <svg-icon icon-class="message"></svg-icon>
          </el-badge>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="message">
              <div class="message-item"><span>{{ $t('common.notice') }}</span><span v-if="messageCount" class="count">{{ messageCount }}</span></div>
            </el-dropdown-item>
            <el-dropdown-item command="comments">
              <div class="message-item"><span>{{ $t('common.comment') }}</span><span v-if="commentCount" class="count">{{ commentCount }}</span></div>
            </el-dropdown-item>
            <el-dropdown-item command="followers">
              <div class="message-item"><span>{{ $t('common.followers') }}</span><span v-if="followerCount" class="count">{{ followerCount }}</span></div>
            </el-dropdown-item>
            <el-dropdown-item command="poked">
              <div class="message-item"><span>{{ $t('common.poked') }}</span><span v-if="pokedCount" class="count">{{ pokedCount }}</span></div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <div class="logoInfo">
          <img
            :src="userInfo.avatar || defaultAvatar"
            alt=""
            class="loginName"
            @mouseenter="enter"
            @mouseleave="leave"
          />
          <div
            v-show="showLogin"
            class="personal"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <div class="triangle"></div>
            <span class="empty"></span>
            <ul class="setInfo">
              <li class="person setting" @click="handleSettings">
                {{ $t("common.settings") }}
              </li>
              <li class="person setting" @click="handleSetPassword">
                {{ $t("common.password") }}
              </li>
              <li class="person signout" @click="handleLogoutClick">
                {{ $t("common.sign_out") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <st-password ref="setPassword"></st-password>
    <st-notice @top="handleTop"></st-notice>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import { mapGetters, mapActions } from "vuex";
import { logout } from "@/api/login";
import stSetPassword from "@/views/settings/password";
import stNotice from "@/components/notice/index";
import StLanguage from "./language";
const defaultAvatar = require("@/assets/images/avatar.png");
export default {
  name: "StHeader",
  components: {
    stPassword: stSetPassword,
    stNotice: stNotice,
    StLanguage,
  },
  data() {
    return {
      defaultAvatar: defaultAvatar,
      showLogin: false,
    };
  },
  computed: {
    ...mapGetters([
      "clientHeight",
      "clientWidth",
      "userInfo",
      "messageCount",
      "commentCount",
      "pokedCount",
      "followerCount",
    ]),
    styles() {
      if (this.clientWidth > 1200) {
        return { width: this.clientWidth + "px" };
      } else {
        return { width: "1200px" };
      }
    },
    unreadCount() {
      return (
        this.messageCount +
        this.commentCount +
        this.pokedCount +
        this.followerCount
      );
    },
  },
  created() {
    this.InitMessage();
    this.InitComment();
    this.InitPoked();
    this.InitFollowers();
  },
  methods: {
    ...mapActions([
      "clearLoginInfo",
      "InitMessage",
      "InitComment",
      "InitPoked",
      "InitFollowers",
    ]),
    handleClickTo() {
      this.$report(`/${this.$route.name}/wm_head/logo`);
      this.$router.push({ path: "/content" });
    },
    enter() {
      this.showLogin = true;
    },
    leave() {
      this.showLogin = false;
    },
    logout() {
      logout().then((res) => {
        this.clearLoginInfo();
        // this.$router.push({
        //   path: '/login'
        // });
        window.location.reload();
      });
    },
    handleSettings() {
      this.$report(`/${this.$route.name}/wm_head/me_settings`);
      this.$router.push({
        name: "settings",
      });
    },
    handleLogoutClick() {
      this.$report(`/${this.$route.name}/wm_head/me_signout`);
      this.logout();
    },
    /** settings password */
    handleSetPassword() {
      this.$report(`/${this.$route.name}/wm_head/me_password`);
      this.$refs.setPassword.show();
    },
    handleCommand(command) {
      switch (command) {
        case "message":
          this.$report(`/${this.$route.name}/wm_head/news_notice`);
          this.$router.push("/message");
          break;
        case "comments":
          this.$report(`/${this.$route.name}/wm_head/news_comment`);
          this.$router.push("/comments");
          break;
        case "poked":
          this.$report(`/${this.$route.name}/wm_head/news_poked`);
          this.$router.push("/followers?type=poked");
          break;
        case "followers":
          this.$report(`/${this.$route.name}/wm_head/news_followers`);
          this.$router.push("/followers?type=followers");
          break;
        default:
          break;
      }
    },
    handleTop() {
      const scrollbarDom =
        document.getElementsByClassName("el-scrollbar__wrap");
      scrollbarDom[0].scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (scrollbarDom[1]) {
        scrollbarDom[1].scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.st-header {
  height: 60px;
  background: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(223, 223, 223, 0.32);
}

.st-logo {
  margin: 0 auto;
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logoImg {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Arial;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    img {
      margin-right: 16px;
      height: 40px;
      width: 40px;
    }
  }
  .inner-right {
    display: flex;
    align-items: center;
  }
  .message {
    margin-right: 26px;
    font-size: 24px;
    height: 24px;
    cursor: pointer;
    .el-badge {
      vertical-align: inherit;
      ::v-deep {
        .el-badge__content {
          top: 4px;
          right: 12px;
          font-size: 10px;
          font-family: Roboto-Bold;
          height: 14px;
          line-height: 14px;
          padding: 0 4.13px;
          border: none;
          background-color: #ee1a1a;
        }
      }
    }
  }
  .logoInfo {
    position: relative;
    .loginName {
      width: 30px;
      height: 30px;
      cursor: pointer;
      border-radius: 20px;
    }
    .personal {
      position: absolute;
      right: -48.5px;
      top: 45px;
      width: 125px;
      height: 120px;
      z-index: 1000;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      padding: 12px 0;
      text-align: center;
      cursor: pointer;
      .setInfo {
        .person {
          height: 34px;
          line-height: 34px;
          margin: 6px 0;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 14px;
          color: #4a4a4a;
        }
      }
      .person:hover {
        background: #f5f5f5;
      }
    }
    .triangle {
      position: absolute;
      top: -20px;
      left: 53.5px;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #e9e9e9 transparent;
    }
    .empty {
      display: block;
      border-width: 10px;
      position: absolute;
      top: -19px;
      left: 53.5px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
  }
}
.message {
  &:focus,
  .el-badge:focus {
    outline: none;
  }
}
.message-item {
  font-family: Roboto-Regular;
  font-size: 14px;
  color: #4a4a4a;
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .count {
    background: #ee1a1a;
    color: #ffffff;
    line-height: 1;
    font-size: 10px;
    font-family: Roboto-Bold;
    border-radius: 14px;
    text-align: center;
    padding: 2px 4.22px;
  }
}
</style>
