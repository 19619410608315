import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const error = () => import('@/views/error/error');
import noLoginRouter from './noLoginRouter';
import constantRouter from './constantRouter';
import otherRouter from './otherRouter';
import asyncRouter from './asyncRouter';
import Main from '@/components/layout/Main.vue';
// import Home from '@/views/home/';
const Home = () => import(/* webpackChunkName: "home" */ '@/views/home/');
const constantRoutes = [
  {
    path: '/',
    redirect: '/login'
  }, {
    path: '/error',
    component: error
  }, {
    path: '/inviteRegister',
    name: 'invite_register',
    component: () => import('@/views/register/InviteRegister')
  },
  // {
  //   path: '/',
  //   component: Main,
  //   children: [
  //     {
  //       path: '/home',
  //       component: Home,
  //       name: 'home'
  //     }
  //   ]
  // },
  noLoginRouter,
  constantRouter,
  otherRouter
]
export const asyncRouterMap = [
  ...asyncRouter
];
export const errorRouter = [
  {
    path: '*',
    redirect: '/error'
  }
];

const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    base: '/',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
