export default {
  common: {
    about: 'About',
    terms: 'Terms of Service',
    privacy: 'Privacy Policy',
    notice: 'Notice',
    comment: 'Comment',
    followers: 'Followers',
    poked: 'Poked',
    settings: 'Settings',
    password: 'Password',
    sign_out: 'Sign out',
    start_date: 'Start date',
    end_date: 'End date',
    range_separator: 'To',
    edit: 'Edit',
    delete: 'Delete',
    appeal: 'Appeal',
    submit: 'Submit',
    cancel: 'Cancel',
    month: 'Month',
    week: 'Week',
    yesterday: 'Yesterday',
    day: 'Day',
    back: 'Back',
    save: 'Save',
    select_one: 'Select One',
    example: 'Example',
    title: 'Title',
    time: 'Time',
    data_trend_table: 'Data trend table',
    province: 'Province',
    city: 'City',
    no_data: 'No data',
    creator_guide: 'Creator Guide',
    feedback: 'Feedback'
  }
}
