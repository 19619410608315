<template>
  <div class="st-slider">
    <div class="st-user">
      <div
        v-for="menu in menuLists.filter((item) => item.id === 'post')"
        :key="menu.id"
        :ref="menu.id"
        class="post-wrap"
        :class="{ 'is-guide': currentGuide === 'post' }"
      >
        <el-button
          class="post"
          type="primary"
          @click="currentGuide !== 'post' ? handleMenuClick('post') : ''"
          >{{ $t(`route.post`) }}</el-button
        >
        <!-- 引导popover -->
        <div v-if="currentGuide === 'post'" class="guide-popover">
          <div class="msg">{{ guideMsg }}</div>
          <el-button type="primary" @click.stop="handleGuide">{{
            $t("guide.know")
          }}</el-button>
        </div>
      </div>
      <div v-loading="loading" class="tab">
        <template
          v-for="(menu, index) in menuLists.filter(
            (item) => item.id !== 'post'
          )"
        >
          <li
            :key="menu.id"
            :ref="menu.id"
            :class="[
              menu.id,
              { 'is-active': active === menu.id || currentGuide === menu.id },
              { 'is-guide': currentGuide === menu.id },
            ]"
            class="tab-item"
            @click="
              currentGuide !== menu.id ? handleMenuClick(menu.id, index) : ''
            "
          >
            <div class="menu-children">
              <svg-icon :icon-class="menu.id"></svg-icon>
              <span class="tab-link">{{ $t(`route.${menu.id}`) }}</span>
            </div>
            <!-- 引导popover -->
            <div v-if="currentGuide === menu.id" class="guide-popover">
              <div class="msg">{{ guideMsg }}</div>
              <el-button type="primary" @click.stop="handleGuide">{{
                $t("guide.know")
              }}</el-button>
            </div>
          </li>
        </template>
      </div>
    </div>
    <!-- <st-account
      ref="accountCheck"
      @success="accountSuccess"
      @cancel="accountCancel"
    ></st-account> -->
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import { mapGetters, mapActions, mapState } from "vuex";
import { userTypeInfo } from "@/api/functions";
const defaultAvatar = require("@/assets/images/avatar.png");
// import StAccount from "@/views/account";
export default {
  name: "StSlider",
  // components: { StAccount },
  data() {
    return {
      defaultAvatar: defaultAvatar,
      loading: false,
      stage: "",
      showGuide: false,
    };
  },
  computed: {
    ...mapGetters(["menuList", "userInfo"]),
    ...mapState("guide", ["guideModal", "guideList"]),
    active() {
      let path = this.$route.path;
      path = path.split("/")[1];
      return path || "content";
    },
    menuLists() {
      const pgcMenu = ["post", "content", "sync"];
      const pgcOnlyMenu = ["sync"];
      const ogcDisMenu = ["sync"];
      const ugcDisMenu = ["analytics", "sync", "comments"];
      const level = this.userInfo.level;
      if (level >= 0 && level <= 9) {
        if (this.userInfo && this.userInfo.videoSync) {
          return this.menuList;
        } else {
          return this.menuList.filter((item) => {
            return pgcOnlyMenu.indexOf(item.id) === -1;
          });
        }
      } else if (level >= 70 && level <= 89) {
        // ugc、miniugc菜单过滤
        return this.menuList.filter((item) => {
          return ugcDisMenu.indexOf(item.id) === -1;
        });
      } else if (level >= 20) {
        // ogc菜单
        return this.menuList.filter((item) => {
          return ogcDisMenu.indexOf(item.id) === -1;
        });
      } else {
        // 非已知level用户
        return this.menuList.filter((item) => {
          return pgcMenu.indexOf(item.id) === -1;
        });
      }
    },
    levelClass() {
      let _class = "";
      switch (this.stage) {
        case 0:
          _class = "fresh";
          break;
        case 1:
          _class = "pro";
          break;
        default:
          break;
      }
      return _class;
    },
    currentGuide() {
      return this.guideList.length > 0 && this.showGuide
        ? this.guideList[0]
        : "";
    },
    guideMsg() {
      let text = "";
      switch (this.currentGuide) {
        case "post":
          text = this.$t("guide.post");
          break;

        case "analytics":
          text = this.$t("guide.analytics");
          break;

        case "functions":
          text = this.$t("guide.functions");
          break;

        case "academy":
          text = this.$t("guide.academy");
          break;

        case "followers":
          text = this.$t("guide.followers");
          break;

        default:
          break;
      }
      return text;
    },
  },
  watch: {
    guideList() {
      this.initGuide();
    },
  },
  created() {
    // userTypeInfo().then((res) => {
    //   res = res.data;
    //   if (res.code === 0) {
    //     this.stage = res.data.stage;
    //   }
    // });
  },
  mounted() {
    this.initGuide();
  },
  methods: {
    ...mapActions("guide", [
      "setGuideModal",
      "saveCurrentGuide",
      "skipCurrentGuide",
    ]),
    handleMenuClick(name) {
      if (this.$route.name === name) return;
      this.$report(`/${this.$route.name}/wm_menu/${name}`);
      // 进入post前进行账号绑定验证
      // if (name === "post") {
      //   this.$refs.accountCheck.init("post");
      //   return;
      // }
      this.$router.push({
        name: name,
      });
    },
    initGuide() {
      if (this.guideList.length > 0) {
        if (this.guideList[0] === "accountCheck") {
          // 账号打通初始化
          this.$refs.accountCheck.init();
        } else {
          // 菜单引导
          if (!this.intersectionObserver) {
            /**
             * 异步观察目标元素与视窗交叉状态
             * @type {IntersectionObserver}
             */
            this.intersectionObserver = new IntersectionObserver(
              (entries) => {
                for (const entry of entries) {
                  if (entry.isIntersecting) {
                    this.setGuideModal(true);
                    this.showGuide = true;
                  } else {
                    this.setGuideModal(false);
                    this.showGuide = false;
                  }
                }
              },
              {
                threshold: 1,
              }
            );
          }
          if (
            this.$refs[this.guideList[0]] &&
            this.$refs[this.guideList[0]].length > 0
          ) {
            console.log(this.$refs[this.guideList[0]][0]);
            this.intersectionObserver.observe(this.$refs[this.guideList[0]][0]);
          }
        }
      } else if (this.intersectionObserver) {
        this.showGuide = false;
      }
    },
    handleGuide() {
      this.intersectionObserver.disconnect();
      this.setGuideModal(false);
      this.saveCurrentGuide(this.currentGuide);
    },
    // 账号绑定提交回调
    accountSuccess() {
      if (this.guideList.length > 0 && this.guideList[0] === "accountCheck") {
        this.saveCurrentGuide(this.guideList[0]);
      }
    },
    // 账号绑定跳过
    accountCancel() {
      if (this.guideList.length > 0 && this.guideList[0] === "accountCheck") {
        this.skipCurrentGuide();
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.st-user {
  position: relative;
  font-family: Roboto-Medium;
}
.post-wrap {
  padding: 20px 20px 10px;
  position: relative;
  .post {
    width: 100%;
  }
}
.tab {
  padding: 0 0 20px 0;
  min-height: 200px;
  .tab-item {
    padding-left: 35px;
    list-style: none;
    cursor: pointer;
    position: relative;
    color: #4a4a4a;
    .menu-children {
      height: 50px;
      display: flex;
      align-items: center;
    }
    .svg-icon {
      font-size: 24px;
    }
    .tab-link {
      font-size: 14px;
      margin-left: 10px;
    }
    &:hover,
    &.is-active {
      color: #ee1a1a;
    }
    &:hover::before,
    &.is-active::before {
      position: absolute;
      content: "";
      width: 4px;
      height: 30px;
      background: #ee1a1a;
      left: 0;
      top: 10px;
    }
  }
}
.is-guide {
  background: #ffffff;
  z-index: 10000;
  border-radius: 6px;
  &::after {
    content: "";
    position: absolute;
    width: 238px;
    height: 58px;
    border: 1px dashed #ffffff;
    left: -4px;
    top: -4px;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .guide-popover {
    position: absolute;
    background: #ffffff;
    left: 266px;
    top: -5px;
    width: 334px;
    padding: 18px 27px 24px;
    font-family: Roboto-Medium;
    font-size: 14px;
    color: #4a4a4a;
    line-height: 24px;
    border-radius: 6px;
    text-align: center;
    box-sizing: border-box;
    &::before {
      position: absolute;
      content: "";
      width: 22px;
      height: 19px;
      left: -20px;
      top: 23px;
      background: url("~@/assets/images/guide.png") no-repeat right center;
    }
    .msg {
      text-align: left;
    }
    .el-button {
      background-image: linear-gradient(270deg, #ff595e 0%, #ff9851 100%);
      box-shadow: 0 4px 13px 0 rgba(238, 26, 26, 0.15);
      border-radius: 20px;
      border: none;
      line-height: 30px;
      width: 100px;
      padding: 0;
      margin-top: 12px;
    }
  }
}
.academy {
  .guide-popover {
    top: unset;
    bottom: -40px;
    &::before {
      top: unset;
      bottom: 53px;
    }
  }
}
.post-wrap {
  &.is-guide {
    background-color: transparent;
    &::after {
      top: 8px;
    }
  }
  .guide-popover {
    top: unset;
    bottom: -40px;
    &::before {
      top: unset;
      bottom: 53px;
    }
  }
}
</style>
