// user
import * as types from '../mutations';
import { getUserInfo } from '@/api/settings';

const ls = localStorage;
// state
const state = {
  userID: ls.getItem('USER_ID') || 'webkong',
  accessToken: ls.getItem('ACCESS_TOKEN') || '',
  fbInfo: JSON.parse(ls.getItem('FACEBOOK_INFO')) || {}, // facebook 登陆返回的信息
  userInfo: ''// 后台接口返回的用户信息
};

// getters

const getters = {
  userID: (state) => state.userID,
  accessToken: (state) => state.accessToken,
  fbInfo: (state) => state.fbInfo,
  userInfo: (state) => state.userInfo
};

// mutations

const mutations = {

  [types.SET_AUTH_INFO](state, { authInfo }) {
    state.accessToken = authInfo.accessToken;
    state.userID = authInfo.userID;
    ls.setItem('ACCESS_TOKEN', JSON.stringify(authInfo.accessToken));
    ls.setItem('USER_ID', JSON.stringify(authInfo.userID));
  },
  [types.SET_FACEBOOK_INFO](state, { FBInfo }) {
    state.fbInfo = FBInfo;
    ls.setItem('FACEBOOK_INFO', JSON.stringify(FBInfo));
  },
  [types.SET_USER_INFO](state, { info }) {
    state.userInfo = info;
  },
  [types.CLEAR_USER_INFO](state) {
    ls.removeItem('FACEBOOK_INFO');
    ls.removeItem('USER_ID');
    ls.removeItem('ACCESS_TOKEN');
    ls.removeItem('LOGIN_TOKEN');
    sessionStorage.removeItem('WeMedia_REFERRER');
  },
  [types.SET_LOGIN_TOKEN](state, { token }) {
    ls.setItem('LOGIN_TOKEN', token);
  }
};

// actions

const actions = {
  setAuthInfo({ commit, state }, authInfo) {
    commit(types.SET_AUTH_INFO, { authInfo });
  },
  setFBInfo({ commit, state }, FBInfo) {
    commit(types.SET_FACEBOOK_INFO, { FBInfo });
  },
  setUerInfo({ commit, state }, info) {
    commit(types.SET_USER_INFO, { info });
  },
  clearLoginInfo({ commit, state }) {
    commit(types.CLEAR_USER_INFO);
  },
  setLoginToken({ commit, state }, token) {
    commit(types.SET_LOGIN_TOKEN, { token });
  },
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const data = res.data;
        if (data.code === 0) {
          commit(types.SET_USER_INFO, { info: data.data });
          resolve();
        } else {
          reject(res);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
