import axios from '@/utils/request';

const login = function(params) {
  return axios.post('/shareit/pgc/user/login', { ...params });
};
const logout = function(params) {
  return axios.get('/shareit/pgc/user/logout', { params: { ...params }});
};
const refresh = function(params) {
  return axios.get('/shareit/pgc/shortVideo/refresh', { params: { ...params }});
};
/**
 * 邀请码注册
 * @param {*} params
 */
export const inviteRegister = (params) => {
  return axios.post('/shareit/pgc/invitaiton/register', { ...params });
};
/**
 * 用户名是否重复
 * @param {*} params
 */
export const exsitUserName = (params) => {
  return axios.get('/shareit/pgc/invitaiton/exsit', { params: { ...params }});
};
/**
 * 普通用户注册
 * @param {*} params
 */
export const register = (params) => {
  return axios.post('/shareit/pgc/account/register/simple', { ...params });
};
/**
 * 重新发送激活链接
 */
export const refreshActive = (params) => {
  return axios.get('/shareit/pgc/account/refreshActive', { params: { ...params }});
};
/**
 * 激活账号
 */
export const emailActive = (params) => {
  return axios.get('/shareit/pgc/account/emailActive', { params: { ...params }});
};
/**
 * 获取加密公钥
 */
export const getPublicKey = (params) => {
  return axios.post('/shareit/pgc/user/pKey', { ...params });
};
/**
 * 发送重置密码验证码
 */
export const sendVeriCode = (params) => {
  return axios.post('/shareit/pgc/passwd/vc/send', { ...params });
};
/**
 * 校验重置密码验证码
 */
export const checkVeriCode = (params) => {
  return axios.post('/shareit/pgc/passwd/vc/check', { ...params });
};
/**
 * 重置密码-提交密码
 */
export const savePassword = (params) => {
  return axios.post('/shareit/pgc/passwd/vc/changePasswd', { ...params });
};
/**
 * 站内修改密码
 */
export const changePassword = (params) => {
  return axios.post('/shareit/pgc/passwd/in/changePasswd', { ...params });
};
/**
 * 邀请码注册 发送邮箱验证码
 */
export const sendInviteRegisterCode = (params) => {
  return axios.post('/shareit/pgc/invitaiton/vc/send', { ...params });
};

/**
 * 获取邮箱网址
 */
export const getEmailUrl = (params) => {
  return axios.get('/shareit/pgc/account/emailJump', { params: { ...params }});
};

/**
 * 注册完善信息
 */
export const initUserInfo = (params) => {
  return axios.post('/shareit/pgc/account/initUserInfo', { ...params });
};

/**
 * 提交同意隐私协议
 */
export const submitAgreeTerms = () => {
  return axios.post('/shareit/pgc/user/submitAgreeTerms');
};

/**
 * 强制修改密码
 */
export const forcePassword = (params) => {
  return axios.post('/shareit/pgc/passwd/change/force', {
    ...params
  });
};

/**
 * 常规注册 发送邮箱验证码
 */
export const sendRegisterCode = (params) => {
  return axios.get('/shareit/pgc/account/email/vcode/send', { params: { ...params }});
};
export {
  login,
  logout,
  refresh
};

/**
 * 手机号登陆发送验证码
 * @param {
 *  "countryCode": "+86",
 *  "phone": "123"
 * }
 */
export const sendPhoneCode = (params) => {
  return axios.post('/shareit/pgc/user/login/phone/verifyCode/send', {
    ...params
  });
};

/**
 * 手机号登陆
 * @param {
 *  "countryCode": "+86",
 *  "phone": "123",
 *  "code": "123"
 * }
 */
export const loginByPhone = (params) => {
  return axios.post('/shareit/pgc/user/login/phone/verifyCode/check', {
    ...params
  });
};
