const Other = () => import('@/components/layout/Other');
const About = () => import('@/views/other/about');
const NoticeDetail = () => import('@/views/home/detail');
const AcademyDetail = () => import('@/views/academy/detail');
const Survey = () => import('@/views/other/survey');
const ActivityApply = () => import('@/views/activity/apply');
const OriginalAppeal = () => import('@/views/functions/originalAppeal');

export default {
  path: '/',
  component: Other,
  children: [
    {
      path: '/notice/:id',
      component: NoticeDetail,
      name: 'NoticeDetail'
    },
    {
      path: '/about',
      component: About,
      name: 'About'
    },
    {
      path: '/academy/:id',
      component: AcademyDetail,
      name: 'AcademyDetail'
    },
    {
      path: '/survey/:id',
      component: Survey,
      name: 'survey'
    },
    {
      path: '/activity/apply',
      component: ActivityApply,
      name: 'activityApply'
    },
    {
      path: '/original/appeal',
      component: OriginalAppeal,
      name: 'originalAppeal'
    }
  ]
}
