import axios from '@/utils/request';

const getUserInfo = function(params) {
  return axios.get('/shareit/pgc/user/getUserInfo', { params: { ...params }});
};

const saveUserInfo = function(params) {
  return axios.post('/shareit/pgc/user/saveUserInfo', { ...params });
};
export {
  getUserInfo,
  saveUserInfo
};
/**
 *  获取用户信息审核结果
 */
export const getAuditInfo = () => {
  return axios.get('/shareit/pgc/user/getAuditInfo');
};
/**
 * 获取领域列表
 */
export const getFields = () => {
  return axios.get('/shareit/pgc/user/getLabelList');
};
/**
 * 获取不可修改字段列表
 */
export const getDisableFields = () => {
  return axios.get('/shareit/pgc/user/getCannotUpdateField');
};
/**
 * 发送验证码
 */
export const sendCode = (params) => {
  return axios.post('/shareit/pgc/user/sendEmailCode', { ...params });
};
/**
 * 提交绑定邮箱
 */
export const saveEmail = (params) => {
  return axios.post('/shareit/pgc/user/verifyEmailCode', { ...params });
};
/**
 * 提交认证信息
 */
export const saveCertification = (params) => {
  return axios.post('/shareit/pgc/user/saveQualificationInfo', { ...params });
};
/**
 * 获取用户冻结信息
 */
export const freezeCheck = () => {
  return axios.get('/shareit/pgc/user/freezeCheck');
};

/**
 * @name 保存手机号
 * @param {*} params
 */
export const savePhone = (params) => {
  return axios.post('/shareit/pgc/user/verifyPhoneCode', { ...params });
};

/**
 * @name 发送手机号验证码
 * @param {*} params
 */
export const sendPhoneCode = (params) => {
  return axios.post('/shareit/pgc/user/sendPhoneCode', { ...params });
};

/**
 * @name 银行国家列表
 * @param {*} params
 */
export const bankCountrys = () => {
  return axios.post('/shareit/pgc/earnings/bank/country/list');
};

/**
 * @name 银行列表
 * @param {*} params
 */
export const bankList = () => {
  return axios.post('/shareit/pgc/earnings/bank/list');
};

/**
 * @name 银行卡信息
 * @param {*} params
 */
export const bankInfo = () => {
  return axios.post('/shareit/pgc/earnings/bank/info');
};

/**
 * @name 绑定银行卡
 * @param {*} params
 */
export const bankBind = (params) => {
  return axios.post('/shareit/pgc/earnings/bank/bind', { ...params });
};

/**
 * @name 更新外站认证信息
 * @param {*} params
 */
export const updateQualificationInfo = (params) => {
  return axios.post('/shareit/pgc/user/updateQualificationInfo', { ...params });
};

/**
 * @name 查询用户c端账号绑定情况
 * @param {*} params
 */
export const checkAccount = (params) => {
  return axios.post('/shareit/pgc/user/checkUserCEndAccounts', { ...params });
};

/**
 * @name 认领账号
 * @param {*} params
 */
export const bindAccount = (params) => {
  return axios.post('/shareit/pgc/user/confirmCEnd', { ...params });
};

/**
 * @name 注销c端账号
 * @param {*} params
 */
export const deleteAccount = (params) => {
  return axios.post('/shareit/pgc/user/deleteCEnd', { ...params });
};

