export default {
  earnings: {
    earnings: 'Pendapatan',
    earnings_instructions: 'Petunjuk Pendapatan',
    yesterday_revenue: 'Penghasilan Kemarin',
    toatl_revenue: 'Total Penghasilan',
    available_balance: 'Saldo Tersedia',
    overall: 'Keseluruhan',
    video: 'Video',
    date: 'Tanggal',
    cpm_daily_revenue: 'CPM Pendapatan Harian (USD)',
    event_revenue: 'Pendapatan Event (USD)',
    cover: 'Cover',
    daily_revenue: 'Pendapatan Harian (USD)'
  }
}
