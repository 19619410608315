import Vue from 'vue';
import ShareitPerformance from '@shareit/performance';

const perf = new ShareitPerformance({
  project: 'pgc-performance',
  groupName: 'sbos',
  appId: 'SHAREit-WeMedia-e773e037e4bb6fd0', // 必传，应用id
  events: ['load'], // 上报事件的页面时机
  plugins: ['performancePlugin'], // 可选
  debug: false, // 调试模式
  spa: true, // 是否单页模式
  env: (process.env.VUE_APP_ENVIRONMENT === 'production' || process.env.VUE_APP_ENVIRONMENT === 'alpha') ? 'prod' : 'test'
})
perf.install() // 初始化！
Vue.prototype.$perf = perf;
