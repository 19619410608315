export default {
  home: {
    fans: 'Followers',
    recommend: 'Recommend',
    clicks: 'Plays',
    bulletin: 'Bulletin Board',
    recommended: 'Recommended Lesson',
    more: 'More',
    creator_event: 'Creator Event'
  }
}
