import NewUser from '@/components/layout/NewUser.vue';
const Login = () => import('@/views/login/login.vue');
export default {
  path: '/',
  component: NewUser,
  children: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: () => import('@/views/register/register')
    // },
    // {
    //   path: '/register/registered',
    //   name: 'registered',
    //   component: () => import('@/views/register/registered')
    // }, {
    //   path: '/register/info',
    //   name: 'setInfo',
    //   component: () => import('@/views/register/setInfo')
    // }, {
    //   path: '/register/reviewing',
    //   name: 'userReviewing',
    //   component: () => import('@/views/register/reviewing')
    // }, {
    //   path: '/register/failed',
    //   name: 'userFailed',
    //   component: () => import('@/views/register/failed')
    // }, {
    //   path: '/register/activation',
    //   name: 'activation',
    //   component: () => import('@/views/register/activation')
    // },
    {
      path: '/banned',
      name: 'banned',
      component: () => import('@/views/login/Banned')
    }, {
      path: '/agreement',
      name: 'agreement',
      component: () => import('@/views/login/Agreement')
    }, {
      path: '/forgetPwd',
      name: 'forgetPwd',
      component: () => import('@/views/forgetPassword/index')
    }, {
      path: '/reset',
      name: 'resetPwd',
      component: () => import('@/views/login/Password')
    }
  ]
}
