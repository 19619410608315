// 弹窗开关
import * as types from '../mutations';

// state
const state = {
  uploadCoverDialog: false
};

// getters

const getters = {
  uploadCoverDialog: (state) => state.uploadCoverDialog
};

// mutations

const mutations = {

  [types.TOGGLE_UPLOAD_COVER_DIALOG](state) {
    state.uploadCoverDialog = !state.uploadCoverDialog;
  },
  [types.CLOSE_UPLOAD_COVER_DIALOG](state) {
    state.uploadCoverDialog = false;
    console.log(state.uploadCoverDialog);
  }
};

// actions

const actions = {
  toggleUploadCoverDialog({ commit, state }) {
    commit(types.TOGGLE_UPLOAD_COVER_DIALOG);
  },
  closeUploadCoverDialog({ commit, state }) {
    commit(types.CLOSE_UPLOAD_COVER_DIALOG);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
