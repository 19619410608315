export default {
  home: {
    fans: 'Pengikut',
    recommend: 'Rekomendasi',
    clicks: 'Plays',
    bulletin: 'Pemberitahuan',
    recommended: 'Rekomendasi Pembelajaran',
    more: 'Lebih',
    creator_event: 'Acara Kreator'
  }
}
