<template>
  <el-dropdown class="lang-select" placement="bottom" @command="handleSetLanguage">
    <div class="switch-btn">
      <svg-icon icon-class="language" />
      <div class="lang-code">{{ language.toUpperCase() }}</div>
      <i class="el-icon-caret-bottom"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language==='en'" command="en">
        English
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='id'" command="id">
        Indonesia
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('SetLanguage', lang);
      this.$message({
        message: 'switch language success',
        type: 'success'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.lang-select {
  margin-right: 18px;
  font-family: Roboto-Regular;
  &:focus {
    outline: none;
  }
}
.switch-btn {
  color: #4A4A4A;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }
  .svg-icon {
    font-size: 24px;
    cursor: pointer;
    vertical-align: middle;
  }
  .lang-code {
    width: 13px;
    font-size: 10px;
    margin-left: 3px;
    margin-right: 2px;
    text-align: center;
  }
  .el-icon-caret-bottom {
    font-size: 10px;
    vertical-align: middle;
  }
}
.el-dropdown-menu {
  .el-dropdown-menu__item {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #4A4A4A;
    &.is-disabled {
      color: #bbbbbb;
    }
  }
}
</style>
