export default {
  route: {
    home: 'Beranda',
    post: 'Pos',
    content: 'Konten',
    comments: 'Komentar',
    analytics: 'Analitik',
    settings: 'Pengaturan',
    sync: 'Sinkronisasi Video',
    functions: 'Fungsi',
    earnings: 'Pendapatan',
    withdraw: 'Tarik Uang',
    academy: 'Creator Academy',
    followers: 'Pengikut',
    targetCenter: 'Target Utama',
    notice_centre: 'Pusat Pemberitahuan'
  }
}
