import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import i18n from './lang';
import '@/icons'; // icon
import * as filters from '@/filters/index';
import * as utils from '@/utils/index';
import '@/assets/styles/index.scss';
import '@/plugins';
import '@/permission'; // permission control
import report from '@/utils/report';

Vue.prototype.$utils = utils;
Vue.prototype.$report = report;
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$ELEMENT = {
  size: 'medium'
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
