export default {
  comments: {
    comments: 'Komentar',
    creator: 'Kreator',
    pinned: 'Pin',
    cancel_pinned: 'Pin Channel',
    like: 'Suka',
    reply: 'Balas',
    cancel_reply: 'Batalkan Balasan',
    pinned_title: 'Apakah kamu yakin untuk pin komentar?',
    cancel_pinned_title: 'Apakah kamu yakin ingin membatalkan komentar untuk dipin?',
    sort_list: [{
      label: 'Terbaru',
      value: 'latest'
    }, {
      label: 'Paling Disukai',
      value: 'likeCount'
    }, {
      label: 'Paling Banyak Dijawab',
      value: 'replyCount'
    }],
    status_list: [{
      label: 'Semua',
      value: ''
    }, {
      label: 'Balas',
      value: 'replied'
    }, {
      label: 'Tidak Dijawab',
      value: 'noReply'
    }]
  }
}
