export default {
  login: {
    login: 'Masuk',
    new_user: 'Pengguna Baru ?',
    sign: 'Daftar',
    phone_login: 'Masuk dengan Nomor Telepon',
    account_login: 'Masuk Akun',
    phone_number: 'Nomor Telepon',
    ver_code: 'Kode Verifikasi',
    get_ver_code: 'Dapatkan Kode Verifikasi',
    name_placeholder: 'Contoh: john@gmail.com',
    password: 'Password',
    pwd_placeholder: 'Password Anda',
    rep_pwd_placeholder: 'Konfirmasi Password',
    forget_pwd: 'Lupa Password?',
    name_required: 'Anda membutuhkan Username',
    pwd_required: 'Password tidak boleh kosong',
    vercode_required: 'Kode Verifikasi tidak boleh kosong. Silakan isi dengan kode yang sudah Anda terima.',
    phone_required: 'Nomor telepon harus diisi',
    // register
    have_account: 'Saya sudah memiliki akun ?',
    resend: 'Kirim Kembali',
    terms_privacy: 'Saya telah membaca dan menyetujui  <a href="http://cdn.ushareit.com/shareit/w/pgc/tos.html" target="_blank">《Terms of Service》</a> serta <a href="http://cdn.ushareit.com/shareit/w/pgc/privacy.html" target="_blank">《Privacy Policy》</a>',
    pwd_rule_text: '1. Password harus terdiri dari 8-20<br />characters in length.<br />2. Password harus mengandung angka, Bahasa Inggris <br />huruf dan simbol.',
    pwd_rule1: 'Password harus terdiri dari 8-20 karakter.',
    pwd_rule2: 'Password harus mengandung angka, alfabet, dan simbol.',
    pwd_rule3: 'Password yang diberikan tidak cocok. Silakan coba kembali.',
    email_required: 'Email harus diisi',
    term_policy_required: 'Anda harus menyetujui Syarat dan Ketentuan dan Kebijakan Privasi.',
    // Lupa Password
    retrieve_password: 'Kembalikan Password',
    email_label: 'Silakan masukkan e-mail',
    email_placeholder: 'Masukkan email',
    email_format: 'Kotak pesan error',
    next: 'Next',
    email_send_success: 'SHAREit telah mengirimkan e-mail yang berisi kode verifikasi',
    new_pwd: 'Password Baru',
    confirm: 'Konfirmasi',
    reset_pwd_success: 'Password berhasil diubah.'
  }
}
