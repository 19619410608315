export default {
  sync: {
    video_sync: 'Video Sync',
    sync_label1: 'Video sync for your account',
    sync_label2: 'YouTube Message',
    sync_label3: 'Channel Url',
    sync_label4: 'Activate',
    sync_label5: 'Auditing',
    sync_label6: 'Nickname',
    sync_label7: 'Status',
    sync_label8: 'Activated',
    sync_label9: 'Remove binding',
    sync_label10: 'Directions',
    sync_label11: '<li>The link of the channel you want to sync must be valid and the same as the one you registered for your account.</li><li>You must be the original producer or owner of the channel, plagiarism or cheat is not allowed. Using the channel of another producer will result in the sync function being closed.</li><li>Ads or unrelated links of other websites or apps are not allowed to show in the videos you are supposed to sync.</li><li>SHAREit WeMedia platform will check the videos synced to your account，the sync process will be stopped if videos contain too many ads or improper contents for our platform.</li><li>More standards about videos not allowed to sync can be seen in "Terms of Service".</li>',
    sync_label12: 'Content source url',
    sync_label13: 'Upload certificate',
    sync_label14: 'Screenshot or your channel homepage;<br>Max for 3 pictures.',
    sync_label15: 'Example',
    sync_label16: 'I have read and agree to the above terms',
    sync_label17: 'License agreement'

  }
}
